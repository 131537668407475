<template>
    <div
        class="content full hide-scrollbar"
        :class="[
            $mq,
            {
                'landscape-tablet': ['landscape', 'desktop'].includes($mq) && (OS() == 'Android' || OS() == 'iOS')
            },
            { condensed: onFocus }
        ]"
        :key="selectedState.id"
    >
        <template v-if="fullPreview">
            <div class="full-preview" @click="maximizePreview()">
                <div class="close-preview" @click="maximizePreview()"></div>
                <Preview :refresh="!scanningQR" :data="newPreviewData" :maximized="true"></Preview>
                <div class="btn-close" @click="maximizePreview()">
                    {{ $t('label.print.minimize') }}
                </div>
            </div>
        </template>
        <template v-else>
            <div id="center-block" class="hide-scrollbar">
                <div class="product-info" :class="{ collection: bShowStates }">
                    <div
                        class="product-title"
                        v-if="!bShowStates"
                        :class="[label.image ? 'image' : '']"
                        :style="{
                            backgroundImage: 'url(' + (label.local_image ? label.local_image : label.image ? label.image : '') + ')'
                        }"
                    >
                        {{ label.name }}
                    </div>
                    <!-- contador collections portrait -->
                    <div class="counter" v-if="bShowStates && ['portrait'].includes($mq)">
                        <div class="value">{{ iStateCompleted }}/{{ states.length }}</div>
                        <div class="description">
                            {{ capitalizeFirstLetter($t('label.print.labels')) }}
                        </div>
                    </div>
                    <div class="collection-btn" :class="{ selected: bShowStates }" v-if="['portrait'].includes($mq) && states && states.length > 1" @click="togglePreviewCollectionPortrait()"></div>
                    <div class="preview-btn" :class="{ selected: showPreviewPortrait }" v-if="['portrait'].includes($mq)" @click="togglePreviewPortrait()"></div>
                </div>
                <!-- wizard mobile -->
                <div class="wizard hide-scrollbar" v-if="processTypes.length > 0 && ['portrait'].includes($mq) && !bShowStates">
                    <!-- <div class="resume hide-scrollbar" v-if="processTypes.length > 2">
                        <div v-for="(pType, i) in processTypes" class="resume-step" :class="[currentStep >= i ? 'done' : 'undone', { current: currentStep == i }]" :style="{ width: 100 / (processTypes.length - 1) + '%' }"></div>
                    </div> -->
                    <div class="nav hide-scrollbar">
                        <div class="action left" @click="changeStep(currentStep - 1)">
                            <template v-if="currentStep > 0">
                                <button class="back"></button>
                                <div class="text" v-if="!['portrait'].includes($mq)">
                                    {{ data[currentStep - 1].title }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="empty"></div>
                            </template>
                        </div>
                        <div class="current-action">
                            {{ data[currentStep].title }}
                        </div>

                        <div
                            class="action right"
                            :class="[
                                {
                                    none: currentStep == processTypes.length - 1
                                },
                                {
                                    disabled: ((data[currentStep].optional == undefined || !data[currentStep].optional) && data[currentStep].result == '') || current_error || blockNextButton
                                }
                            ]"
                            @click="changeStep(currentStep + 1)"
                        >
                            <button class="next"></button>
                        </div>
                    </div>
                </div>
                <!-- wizard desktop -->
                <div class="wizard hide-scrollbar" v-if="processTypes.length > 0 && !['portrait'].includes($mq)">
                    <div class="resume hide-scrollbar" v-if="processTypes.length > 2">
                        <div
                            v-for="(pType, i) in processTypes"
                            class="resume-step"
                            :class="[currentStep >= i ? 'done' : 'undone', { current: currentStep == i }]"
                            :style="{
                                width: 100 / (processTypes.length - 1) + '%'
                            }"
                        ></div>
                    </div>
                    <div class="nav hide-scrollbar">
                        <div class="action left" @click="changeStep(currentStep - 1)">
                            <template v-if="currentStep > 0">
                                <button class="back"></button>
                                <div class="text">
                                    {{ data[currentStep - 1].title }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="empty"></div>
                            </template>
                        </div>
                        <div class="current-action">
                            {{ data[currentStep].title }}
                        </div>
                        <template v-if="currentStep < processTypes.length - 1">
                            <div
                                class="action right"
                                :class="{
                                    disabled: ((data[currentStep].optional == undefined || !data[currentStep].optional) && data[currentStep].result == '') || current_error || blockNextButton
                                }"
                                @click="changeStep(currentStep + 1)"
                            >
                                <div class="text">
                                    {{ data[currentStep + 1].title }}
                                </div>
                                <button class="next"></button>
                            </div>
                        </template>
                        <!-- <template v-else>
            <div class="action right" @click="print()">
              <button
                class="print"
                :class="{disabled: printer_available}"
              >{{$t("label.print.print")}}</button>
            </div>
          </template>-->
                    </div>
                </div>
                <div class="content hide-scrollbar" v-if="!bShowStates || !['portrait'].includes($mq)">
                    <div v-for="(pType, i) in processTypes" :key="i" class="step hide-scrollbar" :class="[data[i].result ? 'done' : 'undone', { current: currentStep == i }]">
                        <template v-if="currentStep == i">
                            <template v-if="!['portrait'].includes($mq) || (['portrait'].includes($mq) && !showPreviewPortrait && !changeDatePrintPortrait)">
                                <template v-if="pType == 'state'">
                                    <div class="tags hide-scrollbar" :class="'p-' + pType">
                                        <div class="carrousel">
                                            <div
                                                :key="index"
                                                v-for="(tag, index) in data[i].tags"
                                                class="tag"
                                                :class="{
                                                    selected: data[i].result == tag.name
                                                }"
                                                @click="setProcessResult(pType, i, tag)"
                                            >
                                                {{ tag.name.length > 40 ? tag.name.substring(0, 40) + '...' : tag.name }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="pType == 'date' || pType == 'datefree'">
                                    <div class="fcalendar-container input" :class="['p-' + pType, { related: filterRelatedLabels }]">
                                        <CalendarSelector :minDate="data[i].minDate" :maxDate="data[i].maxDate" :givenValue="data[i].result" @input="setProcessResult(pType, i, $event)" :key="calendarKey"></CalendarSelector>
                                        <div v-if="filterRelatedLabels" class="related-labels">
                                            <relatedValues :values="filterRelatedLabels" :type="pType" @select="selectRelatedValue(pType, i, $event)"></relatedValues>
                                        </div>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'datepast'">
                                    <div class="fcalendar-container input" :class="['p-' + pType, { related: filterRelatedLabels }]">
                                        <CalendarSelector :minDate="data[i].minDate" :maxDate="data[i].maxDate" :givenValue="data[i].result" @input="setProcessResult(pType, i, $event)" :pastDates="true" :key="calendarKey"></CalendarSelector>
                                        <div v-if="filterRelatedLabels" class="related-labels"><relatedValues :values="filterRelatedLabels" :type="pType" @select="selectRelatedValue(pType, i, $event)"></relatedValues></div>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'datetime' || pType == 'datetimefree' || pType == 'datetimepast'">
                                    <div class="fcalendar-container input" :class="['p-' + pType, { related: filterRelatedLabels }]">
                                        <DateTimeSelector :show="pType == 'datetime'" :minDate="data[i].minDate" :maxDate="data[i].maxDate" :givenValue="data[i].result" @input="setProcessResult(pType, i, $event)" :key="calendarKey"></DateTimeSelector>
                                        <div v-if="filterRelatedLabels" class="related-labels"><relatedValues :values="filterRelatedLabels" :type="pType" @select="selectRelatedValue(pType, i, $event)"></relatedValues></div>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'time'">
                                    <div class="input-number" :class="'p-' + pType">
                                        <!-- :key="selectTimeHour" -->
                                        <HourTimeSelector :givenValue="data[i].result" @input="setProcessResult(pType, i, $event)"></HourTimeSelector>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'string'">
                                    <div class="input-text input" :class="['p-' + pType, { related: filterRelatedLabels }]">
                                        <InputTicketing :ref="`step-${i}`" required :givenValue="data[i].result" @handleFocus="condensedView" @input="setProcessResult(pType, i, $event)" @blockNext="blockNext" :key="currentStep"></InputTicketing>
                                        <div v-if="filterRelatedLabels" class="related-labels"><relatedValues :values="filterRelatedLabels" :type="pType" @select="selectRelatedValue(pType, i, $event)"></relatedValues></div>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'number'">
                                    <div class="input-number" :class="'p-' + pType">
                                        <Numpad @sendValue="setProcessValue(pType, i, $event)" :decimal="true" :givenValue="data[i].result" :max="data[i].max ? data[i].max : null"></Numpad>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'quantity'">
                                    <div class="input-number" :class="'p-' + pType">
                                        <Numpad @sendValue="setProcessValue(pType, i, $event)" :quantity="true" :givenValue="data[i].result ? data[i].result : label.quantity ? label.quantity : '1'" :startNumber="1" :max="data[i].max ? data[i].max : null"></Numpad>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'barcode'">
                                    <div class="input-number" :class="'p-' + pType">
                                        <Barcode @sendValue="setProcessValue(pType, i, $event)" @nextStep="changeStep(currentStep + 1)" @handleFocus="condensedView" :givenValue="data[i].result"></Barcode>
                                    </div>
                                </template>

                                <template v-else-if="pType == 'previous_labels'">
                                    <div>
                                        <PreviousLabels @sendValue="setProcessResult(pType, i, $event)" @nextStep="changeStep(currentStep + 1)" :state="label.states[processData.selectedState.id]" :rules="data[i].rules" :givenValue="data[i].result" :referenceId="label.id"></PreviousLabels>
                                    </div>
                                </template>
                            </template>
                        </template>
                        <template v-if="isLoadedRelatedLabels">
                            <div class="alert-box" v-if="currentStep > 0 && currentStep < data.length - 1 && isErrorRelatedLabels">
                                <ConnectionBox gType="connection" @reload="reloadRelatedLabels"></ConnectionBox>
                            </div>
                        </template>
                    </div>
                </div>

                <!-- Collection PORTRAIT -->
                <div class="preview-info hide-scrollbar" v-if="bShowStates && ['portrait'].includes($mq)">
                    <!-- <div class="titl">{{ $t('label.print.labels_to_print') }}</div> -->

                    <div class="states_labels hide-scrollbar" :key="iStateCompleted">
                        <StateLabel v-for="state in states" :key="state.stateID + state.collectionID + state.referenceID" :stateID="state.stateID" :collectionID="state.collectionID" :referenceID="state.referenceID" :name="state.name" :state="state.state" :image="state.image" :quantity="state.quantity" :selectedState="label" @changeSelectedState="changeSelectedState($event)"></StateLabel>
                    </div>
                </div>
                <div class="footer" v-if="['portrait'].includes($mq) && !changeDatePrintPortrait && !bShowStates">
                    <button @click="cancel()" class="act cancel">
                        {{ $t('label.print.cancel') }}
                    </button>

                    <button
                        v-if="currentStep < processTypes.length - 1"
                        class="act save"
                        :class="{
                            disabled: ((data[currentStep].optional == undefined || !data[currentStep].optional) && data[currentStep].result == '') || current_error || blockNextButton
                        }"
                        @click="changeStep(currentStep + 1)"
                    >
                        {{ $t('label.print.next') }}
                    </button>
                    <button
                        v-else
                        class="act save print"
                        :class="{
                            disabled: (printer_available && !digitalState) || current_error || blockNextButton || parseInt(data[processTypes.length - 1].result) == 0 || parseInt(data[processTypes.length - 1].result) > parseInt(data[processTypes.length - 1].max),
                            digital: digitalState
                        }"
                        @click=";(printer_available && !digitalState) || parseInt(data[processTypes.length - 1].result) == 0 || parseInt(data[processTypes.length - 1].result) > parseInt(data[processTypes.length - 1].max) ? false : print()"
                    >
                        {{ digitalState ? $t('label.print.save') : $t('label.print.print') }}
                    </button>
                </div>
            </div>

            <div class="column right hide-scrollbar" v-if="!['portrait'].includes($mq) || (['portrait'].includes($mq) && showPreviewPortrait && !changeDatePrintPortrait)">
                <div v-if="states && states.length > 1 && !['portrait'].includes($mq)" class="collections_header">
                    <div class="counter">
                        <div class="value">{{ iStateCompleted }}/{{ states.length }}</div>
                        <div class="description">
                            {{ capitalizeFirstLetter($t('label.print.labels')) }}
                        </div>
                    </div>
                    <div class="change_view">
                        <span @click="bShowStates = !bShowStates">{{ !bShowStates ? $t('label.print.see_all_labels') : $t('label.print.see_preview') }}</span>
                    </div>
                </div>

                <!-- Category -->
                <template v-if="Object.keys(getPrinters).length > 1">
                    <div class="titl">
                        {{ $t('label.print.title_printer') }}
                        <select
                            :value="currentPrinter"
                            @input="
                                (v) => {
                                    currentPrinter = v.target.value
                                }
                            "
                        >
                            <option v-for="printer in getPrinters" :key="printer.id" :value="printer.id">
                                {{ printer.name }}
                            </option>
                        </select>
                    </div>
                </template>
                <template v-if="!bShowStates && (previewPrint.length > 0 || newPreview)">
                    <div
                        class="preview-info hide-scrollbar"
                        :class="[
                            {
                                'portrit-selector': ['portrait'].includes($mq) && Object.keys(getPrinters).length > 1
                            },
                            {
                                'desktop-labelling-collection': ['desktop'].includes($mq) && states && states.length > 1
                            },
                            {
                                'landscape-labelling-collection': ['landscape'].includes($mq) && states && states.length > 1
                            },
                            { 'desktop-labelling': ['desktop'].includes($mq) },
                            {
                                'landscape-labelling': ['landscape'].includes($mq)
                            }
                        ]"
                    >
                        <div class="preview-title">
                            <div class="titl">
                                {{ ['portrait'].includes($mq) ? $t('label.print.title_short') : $t('label.print.title') }}
                            </div>
                            <div class="maximize-preview" v-if="newPreview && ['landscape', 'desktop'].includes($mq)" @click="maximizePreview()"></div>
                        </div>

                        <div class="preview scrollbar" v-if="!newPreview">
                            <div class="row" v-for="(row, i) in previewPrint" :key="i">
                                <div class="col" v-for="(col, j) in row" :class="['c' + col.l, col.current ? 'current' : '']" :key="j">
                                    <VueBarcode v-if="col.barcode" v-bind:value="col.v" :height="barcodeConfig.height" :format="barcodeConfig.format">
                                        <span v-html="col.v != '' ? col.v : '-'"></span>
                                    </VueBarcode>
                                    <span v-else v-html="col.v != '' ? col.v : '-'"></span>
                                </div>
                            </div>
                            <!-- <div class="row" v-if="previewPrint.length == 0">
                            <div class="col c4"><span v-html="$t('label.print.errors.invalid_configuration')"></span></div>
                        </div> -->
                        </div>
                        <Preview v-else :refresh="!scanningQR" :key="currentStep" :calculate="calculate" :data="newPreviewData"></Preview>

                        <template v-if="type != 'timerproduct' && (bFreeLabelling || bPastLabelling)">
                            <div class="print_date" v-if="['portrait'].includes($mq)">
                                <div class="section">
                                    <div class="meta">
                                        <div class="label">
                                            {{ $t('label.print.print_date') }}
                                        </div>
                                        <div class="value">
                                            {{ !bUpdatedPrintDateVisible ? init_date.format('DD/MM/YYYY HH:mm') : print_date.format('DD/MM/YYYY HH:mm') }}
                                        </div>
                                    </div>
                                    <div class="action" @click.self="changeDatePrintPortrait = !changeDatePrintPortrait">
                                        {{ $t('menu_aux.action.change') }}
                                    </div>
                                </div>
                                <div class="section date" v-if="bUpdatedPrintDateVisible">
                                    <div class="modify_date">
                                        {{ $t('label.print.modify_date') }}
                                    </div>
                                </div>
                            </div>
                            <div class="print_date" v-else @click="changeDatePrintPortrait = !changeDatePrintPortrait">
                                <div class="section">
                                    <div class="label">
                                        {{ $t('label.print.print_date') }}
                                    </div>
                                    <div class="value" @click="changePrintDate('')">
                                        {{ !bUpdatedPrintDateVisible ? init_date.format('DD/MM/YYYY HH:mm') : print_date.format('DD/MM/YYYY HH:mm') }}
                                    </div>
                                </div>
                                <div class="section" v-if="bUpdatedPrintDateVisible">
                                    <div class="modify_date">
                                        {{ $t('label.print.modify_date') }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="quantity" v-if="['landscape', 'desktop'].includes($mq)">
                            <span>
                                <span class="units">{{ data[data.length - 1].result != '' ? data[data.length - 1].result : label.quantity ? label.quantity : '1' }}</span>
                                {{ parseInt(data[data.length - 1].result) > 1 ? $t('label.print.labels') : $t('label.print.label') }}
                            </span>
                        </div>
                    </div>
                </template>

                <!-- INVALID PRINTER -->
                <template v-else-if="!bShowStates && previewPrint.length == 0 && !newPreview">
                    <div class="preview-error">
                        <div class="error-message" v-html="$t('label.print.errors.invalid_configuration')"></div>
                        <button class="btn" @click="goToConfig">
                            {{ $t('label.print.errors.invalid_configuration_action') }}
                        </button>
                    </div>
                </template>

                <!-- Collection -->
                <template v-else>
                    <div
                        class="preview-info collection hide-scrollbar"
                        :class="{
                            'collection-desktop': ['desktop'].includes($mq)
                        }"
                    >
                        <div class="titl">
                            {{ $t('label.print.labels_to_print') }}
                        </div>

                        <div class="states_labels hide-scrollbar" :key="iStateCompleted">
                            <StateLabel v-for="state in states" :key="state.stateID + state.collectionID + state.referenceID" :stateID="state.stateID" :collectionID="state.collectionID" :referenceID="state.referenceID" :name="state.name" :state="state.state" :image="state.image" :quantity="state.quantity" :selectedState="label" @changeSelectedState="changeSelectedState($event)"></StateLabel>
                        </div>
                    </div>
                </template>

                <!-- <div class="portrait-footer" v-if="['portrait'].includes($mq)"> -->
                <!-- <button @click="cancel()" class="act cancel">{{ $t('label.print.cancel') }}</button> -->

                <!-- <button v-if="currentStep < processTypes.length - 1" class="act save" @click="togglePreviewPortrait()">{{ $t('menu_aux.action.continue') }}</button>
                    <button v-else class="act save print" :class="{ disabled: (printer_available && !digitalState) || current_error || parseInt(data[processTypes.length - 1].result) == 0 || parseInt(data[processTypes.length - 1].result) > parseInt(data[processTypes.length - 1].max), digital: digitalState }" @click=";(printer_available && !digitalState) || parseInt(data[processTypes.length - 1].result) == 0 || parseInt(data[processTypes.length - 1].result) > parseInt(data[processTypes.length - 1].max) ? false : print()">{{ digitalState ? $t('label.print.save') : $t('label.print.print') }}</button> -->
                <!-- </div> -->
                <div class="footer" v-if="['landscape', 'desktop'].includes($mq) || (['portrait'].includes($mq) && !showPreviewPortrait && !changeDatePrintPortrait)">
                    <button @click="cancel()" class="act cancel">
                        {{ $t('label.print.cancel') }}
                    </button>

                    <button
                        v-if="currentStep < processTypes.length - 1"
                        class="act save"
                        :class="{
                            disabled: ((data[currentStep].optional == undefined || !data[currentStep].optional) && data[currentStep].result == '') || current_error || blockNextButton
                        }"
                        @click="changeStep(currentStep + 1)"
                    >
                        {{ $t('label.print.next') }}
                    </button>
                    <button
                        v-else
                        class="act save print"
                        :class="{
                            disabled: (printer_available && !digitalState) || current_error || blockNextButton || parseInt(data[processTypes.length - 1].result) == 0 || parseInt(data[processTypes.length - 1].result) > parseInt(data[processTypes.length - 1].max),
                            digital: digitalState
                        }"
                        @click=";(printer_available && !digitalState) || parseInt(data[processTypes.length - 1].result) == 0 || parseInt(data[processTypes.length - 1].result) > parseInt(data[processTypes.length - 1].max) ? false : print()"
                    >
                        {{ digitalState || type == 'timerproduct' ? $t('label.print.save') : $t('label.print.print') }}
                    </button>
                </div>
            </div>

            <date-time-portrait :only_past="bPastLabelling" @cancel="changeDatePrintPortrait = false" @updatePrintDate="changePrintDate" v-else-if="changeDatePrintPortrait && showPreviewPortrait" :value="!bUpdatedPrintDateVisible ? init_date.format('DD/MM/YYYY HH:mm') : print_date.format('DD/MM/YYYY HH:mm')" :date="print_date.format('DD/MM/YYYY')" :time="print_date.format('HH:mm')" :changePrintDate="changeDatePrintPortrait"></date-time-portrait>
        </template>
    </div>
</template>

<script>
import Preview from '@/components/domain/label/preview.vue'
import i18n from '@/i18n'
import Numpad from '@/components/inputs/Numpad'
import InputTicketing from '@/components/inputs/InputTicketing'
import CalendarSelector from '@/components/inputs/CalendarSelector'
import HourTimeSelector from '@/components/inputs/HourTimeSelector'
import DateTimeSelector from '@/components/inputs/DateTimeSelector'
import StateLabel from '@/components/domain/label/StateLabel'
import Barcode from '@/components/inputs/Barcode'
import VueBarcode from 'vue-barcode'
import DateTimePortrait from '@/components/domain/label/dateTimePortrait.vue'
import relatedValues from '@/components/domain/label/relatedValues'
import PreviousLabels from '@/components/domain/label/previousLabels'
import ConnectionBox from '../ui/Box.vue'

export default {
    name: 'Print',
    components: {
        Numpad,
        InputTicketing,
        CalendarSelector,
        DateTimeSelector,
        HourTimeSelector,
        StateLabel,
        Barcode,
        VueBarcode,
        Preview,
        DateTimePortrait,
        relatedValues,
        PreviousLabels,
        ConnectionBox
    },

    props: {
        states: {
            type: Array,
            default: []
        },
        printer_available: Boolean,
        labelStateSelected: String,
        type: {
            type: String,
            default: 'label'
        }
    },
    data() {
        return {
            calculate: 0,
            fullPreview: false,
            newPreviewData: {},
            newPreview: true,
            selectTimeHour: 0,
            data: [],
            processData: {},
            processTypes: [],
            currentStep: 0,
            oParams: {},
            printData: [],
            previewPrint: [],
            onFocus: false,
            current_error: false,
            current_template_error: false,
            bShowStates: false,
            selectedState: {},
            iStateIndex: 0,
            iStateCompleted: 0,
            label: {},
            init_date: false,
            print_date: false,
            bUpdatedPrintDate: false,
            bUpdatedPrintDateVisible: false,
            barcodeConfig: {
                format: 'CODE39',
                height: '35'
            },
            digitalState: false,
            blockNextButton: false,

            // PORTRAIT VARIABLES OF CONTROL
            showPreviewPortrait: false,
            changeDatePrintPortrait: false,
            timezone: null,
            relatedLabels: {},
            // create custom key to avoid problems for using date[i].result in this case
            // (rerender when is not supposted to when click on calendar or time)
            calendarKey: 0,
            autoDateCalc: false,
            isLoadedRelatedLabels: false,
            isErrorRelatedLabels: false
        }
    },
    computed: {
        bFreeLabelling() {
            return parseInt(this.$store.getters['getFreeLabelling']) == 1
        },

        bPastLabelling() {
            return parseInt(this.$store.getters['getPastLabelling']) == 1
        },

        connection() {
            return this.$store.getters['getConnState']
        },

        currentPrinter: {
            get() {
                var config_id = this.$store.getters['printer/getCurrentPrinter']
                if (config_id && typeof this.getPrinters[config_id] !== 'undefined') return config_id
                this.$store.dispatch('printer/setCurrentPrinter', Object.keys(this.getPrinters)[0])
                return Object.keys(this.getPrinters).length > 0 ? Object.keys(this.getPrinters)[0] : false
            },
            set(value) {
                this.$store.dispatch('printer/setCurrentPrinter', value)
            }
        },

        checkingPrinter() {
            return this.$store.getters['printer/isCheckingStatusPrinter']
        },

        printerAvailable() {
            return this.$store.getters['printer/getStatusPrinter']
        },

        getPrinters() {
            var printers = this.$store.getters['printer/getPrinterConfig']()
            // console.log('PRINTERS', printers)
            var oPrinters = {}
            for (var p in printers) {
                if (this.$store.getters.getIsApp && (printers[p].mode == 'WIFI' || printers[p].print_mode == 'wifi' || printers[p].platform == cordova.platformId)) {
                    oPrinters[p] = printers[p]
                } else if (!this.$store.getters.getIsApp && printers[p].platform == 'windows') {
                    oPrinters[p] = printers[p]
                }
            }

            return oPrinters
        },

        scanningQR() {
            return this.$store.getters['getBarcodeActive']
        },
        filterRelatedLabels() {
            const labels = this.relatedLabels[this.processData.selectedState.id]
            const stepKeyName = this.data[this.currentStep].keyname
            const showRelatedLabels = !this.data[this.currentStep].rules || typeof this.data[this.currentStep].rules.related === 'undefined' || (this.data[this.currentStep].rules && this.data[this.currentStep].rules.related == true)

            let result = false

            if (typeof labels !== 'undefined' && typeof labels[stepKeyName] !== 'undefined' && showRelatedLabels) {
                result = []
                for (let label in labels[stepKeyName]) {
                    let value = labels[stepKeyName][label]

                    if (result.indexOf(value) == -1) {
                        result.push(value)
                    }
                }
            }

            return result.length > 0 ? result : false
        },

        tpRound() {
            return this.$store.getters.getTimerProductRound
        }
    },
    methods: {
        // PORTRAIT METHODS
        togglePreviewPortrait() {
            const serverTimeDifference = this.$store.getters['getServerTimeDifference']

            if (this.showPreviewPortrait) {
                this.showPreviewPortrait = false
                this.changeDatePrintPortrait = false
            } else {
                this.showPreviewPortrait = true
                this.bShowStates = false

                // get date by timezone not from device
                let date = moment()
                if (!this.bUpdatedPrintDate) {
                    try {
                        const options = {
                            timeZone: this.timezone,
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        }
                        const copy = moment(date)

                        log('1', date.format('DD/MM/YYYY HH:mm'))

                        const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                        const objectDate = {}
                        for (const item of dateTimeFormat) {
                            objectDate[item.type] = item.value
                        }
                        const dateString = `${objectDate['day'].padStart(2, '0')}/${objectDate['month'].padStart(2, '0')}/${objectDate['year']} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                        date = moment(dateString, 'DD/MM/YYYY HH:mm')

                        log('2', date.format('DD/MM/YYYY HH:mm'))
                        log('3', date.diff(copy, 'minutes'))
                        log('4', serverTimeDifference)

                        if (this.$store.getters.getDateLimitation == 0 && date.diff(copy, 'minutes') != serverTimeDifference) {
                            const difference = serverTimeDifference - date.diff(copy, 'minutes')
                            date = date.add(difference, 'minutes')
                        }
                    } catch (error) {
                        logError(error)
                    }
                }

                this.print_date = this.bUpdatedPrintDate ? this.print_date : date
                this.init_date = date
                this.updatePreview()
            }
        },
        togglePreviewCollectionPortrait() {
            if (this.bShowStates) {
                this.bShowStates = false
            } else {
                this.bShowStates = true
                this.showPreviewPortrait = false
            }
        },

        maximizePreview() {
            this.fullPreview = !this.fullPreview
            // this.calculate += 1
        },
        validatePrinterConfiguration(bLaunchUpdate, validatePrinter) {
            // VALIDATE IF CURRENT TEMPLATE IF VALID FOR CURRENT PRINTER
            // 1) THIS CAN BE WHEN TEMPLATE EXISTS ON PRINTER
            // CAN BE TOO WHEN EXISTS TEMPLATE ON PRINTER WHERE HIS PARENT_ID IS THE TEMPLATE
            // 2) WE NEED VALIDATE THEN IF CURRENT PRINTER HAD ALL TEMPLATES LOADED ON PRINTER OR DOWNLOAD IN PATH
            var bLaunchUpdate = typeof bLaunchUpdate !== 'undefined' ? bLaunchUpdate : true
            var validatePrinter = typeof validatePrinter !== 'undefined' ? validatePrinter : false
            var template = this.processData.label.states[this.processData.selectedState.id].format
            var currentPrinterConfig = this.getPrinters[this.currentPrinter]
            var currentPrinter = this.currentPrinter ? this.$store.getters['printer/getModels'](currentPrinterConfig.model) : false
            var templatesPrinter = currentPrinter ? this.$store.getters['printer/getTemplatesByPrinterModel'](currentPrinter.id) : []

            var bErrorFound = false
            // console.log('template -> ', template)
            // console.log('currentPrinterConfig -> ', currentPrinterConfig)
            // console.log('currentPrinter -> ', currentPrinter)
            // console.log('templatesPrinter -> ', templatesPrinter)
            if (currentPrinter && typeof templatesPrinter[template] === 'undefined') {
                var templates = this.$store.getters['printer/getTemplates']()
                var bFound = false
                for (var t in templates) {
                    var tpl = templates[t]
                    if (tpl.printer_id == currentPrinterConfig.model && tpl.parent_id == template) {
                        bFound = true
                        log('EXISTE UN TEMPLATE PADRE!', tpl.id, tpl.parent_id, template)
                    }
                }
                bErrorFound = !bFound
            }
            if (currentPrinter && currentPrinterConfig.platform == 'android' && currentPrinter.sdk == 'brother' && (typeof templatesPrinter[template] === 'undefined' || typeof currentPrinterConfig.templates[templatesPrinter[template].label_id] === 'undefined')) {
                var label = templatesPrinter[template] ? this.$store.getters['printer/getLabels'](templatesPrinter[template].label_id) : false
                if (label && !label.tpl_file_app.includes('.z')) {
                    var templates = this.$store.getters['printer/getTemplates']()
                    var bFound = false
                    for (var t in templates) {
                        var tpl = templates[t]
                        if (tpl.printer_id == currentPrinterConfig.model && tpl.parent_id == template && typeof currentPrinterConfig.templates[templatesPrinter[tpl.id].label_id] !== 'undefined') {
                            bFound = true
                        }
                    }
                    bErrorFound = !bFound
                }
            }

            if (bErrorFound) {
                this.current_template_error = true
                this.$snackbar.error({
                    message: i18n.t('config.printer.errors.default2'),
                    closeable: true
                })
                this.previewPrint = []
                this.currentStep = 0
            } else {
                var self = this
                this.$store.dispatch('printer/checkPrinterConfiguration', {}).then(function () {
                    var update = self.$store.getters['printer/getUpdateTemplate']
                    if (update) {
                        self.$popup.alert({
                            closable: false,
                            blocking: false,
                            customClass: 'popup-label-admin',
                            message: self.$t('label.update.message'),
                            textSave: self.$t('label.update.confirm'),
                            textCancel: self.$t('label.update.cancel'),
                            callSave: function () {
                                // self.$router.push("/config/printer");
                                self.$router.push({
                                    name: 'ConfigPage',
                                    params: {
                                        page: 'printer',
                                        update: true,
                                        config_id: update
                                    }
                                })
                            }
                        })
                    } else {
                        if (!self.checkingPrinter) {
                            if (validatePrinter) {
                                if (self.$store.getters.getIsApp) {
                                    self.$store.dispatch('printer/statusPrinter')
                                } else {
                                    self.$store.dispatch('printer/validateBpac')
                                }
                            }

                            self.current_template_error = false
                            self.$snackbar.close()
                            // console.log('validatePrinterConfiguration', bLaunchUpdate)
                            if (bLaunchUpdate) self.updatePreview()
                        }
                    }
                })
            }
        },
        // GET OPERATING SYSTEM
        OS() {
            var system = getOS()
            return system
        },

        setProcessResult(pType, i, result) {
            logError('setProcessResult', pType, i, result)

            this.processBeforeChanges(pType, i, result)
            const serverTimeDifference = this.$store.getters['getServerTimeDifference']

            if (pType == 'state') {
                this.selectedState.name = result.name
                this.selectedState.id = result.id
                this.selectedState.digital = result.digital
                this.blockNextButton = false

                // this.processData.selectedState.name = result.name
                // this.processData.selectedState.id = result.id
                // this.data[i].result = result.name;
                // this.data[i].selected = result.id;

                this.generateProcess(false)

                this.autoDateCalc = this.processTypes.includes('previous_labels')
                // Calculo de maxDate en funcion de estado
                // var datePos = this.processTypes.indexOf('date');
                // this.data[datePos].maxDate = moment().add(this.data[i].tags[result.id].time, 'minutes').format('D/M/YYYY');
            } else if (pType == 'date' || pType == 'datepast') {
                this.data[i].result = moment(result, 'YYYY/MM/DD').format('DD/MM/YYYY') + moment().add(serverTimeDifference, 'minutes').format(' HH:mm')
                // alert('1 ' + this.data[i].result)
            } else if (pType == 'time') {
                this.data[i].result = result
            }
            // else if (pType == "datetime") {
            //   let date =
            //     Object.keys(this.data[i].date).length > 0
            //       ? this.data[i].date.selectedDate
            //       : "";
            //   let time = this.data[i].time ? this.data[i].time : "";

            //   this.data[i].result = date + " " + time;
            // }
            else if (['datefree', 'datetimefree'].includes(this.data[i].type)) {
                if (result != '') {
                    // console.log(this.data[i], result)
                    if (this.data[i].type == 'datefree') result = moment(result, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm')
                    this.data[i].result = moment(result, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')

                    if (!this.data[i].rules || typeof this.data[i].rules.modprintd === 'undefined' || this.data[i].rules.modprintd) {
                        this.oParams['initdate'] = this.data[i].result
                    }
                }
                // console.log('New PRINT DATE - ', result)
                // this.print_date = moment(result, 'YYYY/MM/DD HH:mm')
                // this.bUpdatedPrintDate = true
            } else if (pType == 'previous_labels') {
                let label = JSON.parse(result)
                this.autoDateCalc = true

                for (var k in label) {
                    if (!['caducidad','date','prep_date','employee', 'employee_name', 'product', 'bUpdatedPrintDate', 'state', 'quantity', 'cantidad'].includes(k)) {
                        if (['note', 'note1', 'note2', 'note3'].includes(k) && (!this.oParams[k] || this.oParams[k].trim() == '')) {
                            this.oParams[k] = label[k]
                        } else if (this.oParams[k]) {
                            this.oParams[k] = label[k]
                        }

                        if (k.includes('_timestamp')) {
                            console.log('PL: ', k)
                            let k_aux = k.replace('_timestamp', '')
                            let dateAux = moment(parseInt(label[k]) * 1000)

                            if (parseInt(label[k_aux]) == label[k_aux]) {
                                // CASO BARCODE LA FECHA EN LA VARIABLE ES UN TIMESTAMP
                                this.oParams[k_aux] = dateAux.format('DD/MM/YYYY HH:mm') //initial value
                            } else {
                                // LA FECHA VIENE EN EL FORMATO ESPERADO
                                this.oParams[k_aux] = label[k_aux] //initial value
                            }

                            this.oParams[k] = label[k] //timestamp
                            this.oParams[k_aux + '_datetime'] = this.oParams[k_aux]
                            this.oParams[k_aux + '_d1'] = dateAux.isoWeekday() == 1 ? 'x' : ' '
                            this.oParams[k_aux + '_d2'] = dateAux.isoWeekday() == 2 ? 'x' : ' '
                            this.oParams[k_aux + '_d3'] = dateAux.isoWeekday() == 3 ? 'x' : ' '
                            this.oParams[k_aux + '_d4'] = dateAux.isoWeekday() == 4 ? 'x' : ' '
                            this.oParams[k_aux + '_d5'] = dateAux.isoWeekday() == 5 ? 'x' : ' '
                            this.oParams[k_aux + '_d6'] = dateAux.isoWeekday() == 6 ? 'x' : ' '
                            this.oParams[k_aux + '_d7'] = dateAux.isoWeekday() == 7 ? 'x' : ' '
                            this.oParams[k_aux + '_day'] = dateAux.format('DD/MM/YYYY')
                            this.oParams[k_aux + '_time'] = dateAux.format('HH:mm')
                        } else if (k.match(/ingr.{1,2}date$/gi) || k.match(/ingr.{1,2}rec$/gi)) {
                            let dateAux = moment(label[k], 'DD/MM/YYYY HH:mm')
                            this.oParams[k] = label[k]
                            this.oParams[k + '_datetime'] = this.oParams[k]
                            this.oParams[k + '_d1'] = dateAux.isoWeekday() == 1 ? 'x' : ' '
                            this.oParams[k + '_d2'] = dateAux.isoWeekday() == 2 ? 'x' : ' '
                            this.oParams[k + '_d3'] = dateAux.isoWeekday() == 3 ? 'x' : ' '
                            this.oParams[k + '_d4'] = dateAux.isoWeekday() == 4 ? 'x' : ' '
                            this.oParams[k + '_d5'] = dateAux.isoWeekday() == 5 ? 'x' : ' '
                            this.oParams[k + '_d6'] = dateAux.isoWeekday() == 6 ? 'x' : ' '
                            this.oParams[k + '_d7'] = dateAux.isoWeekday() == 7 ? 'x' : ' '
                            this.oParams[k + '_day'] = dateAux.format('DD/MM/YYYY')
                            this.oParams[k + '_time'] = dateAux.format('HH:mm')
                        } else {
                            let bFound = false
                            this.data.forEach((step) => {
                                if (step.keyname == k) {
                                    step.result = label[k]
                                    bFound = true
                                }
                            })
                            if (!bFound && typeof this.oParams[k] !== 'undefined') {
                                this.oParams[k] = label[k]
                            }
                        }
                    }
                }

                if (this.oParams['date'] && this.oParams['date'].trim() != '') {
                    const serverTimeDifference = this.$store.getters['getServerTimeDifference']
                    let currentTime = moment().add(serverTimeDifference, 'minutes')

                    if (moment(this.oParams['date'], 'DD/MM/YYYY HH:mm').isBefore(currentTime)) {
                        this.blockNextButton = true
                        this.$snackbar.error({
                            message: i18n.t('tooltip.print_invalid_date'),
                            closeable: true
                        })
                    } else {
                        this.blockNextButton = false
                    }
                }
                console.log('oparams', JSON.parse(JSON.stringify(this.oParams)))
                this.data[i].result = true
            } else {
                this.data[i].result = result
            }
            this.data[i] = { ...this.data[i] }
            this.data = JSON.parse(JSON.stringify(this.data))
            this.processAfterChanges(pType, i, result)
        },

        setProcessValue(pType, i, result) {
            // console.log('setProcessValue -> pType, i, result', pType, i, result)

            this.processBeforeChanges(pType, i, result)

            this.data[i].result = result

            this.processAfterChanges(pType, i, result)
        },

        processBeforeChanges(pType, i, result) {
            // console.log('processBeforeChanges -> result', result)
            var hasResult = this.data[i].optional || (this.data[i].result && this.data[i].result != null && this.data[i].result != '')

            if (hasResult && this.data[i].result != result) {
                for (var k = i; k < this.data.length; k++) {
                    var datos = this.data[k]
                    if (i == 0) datos.result = '' // SOLO RESETEAMOS VALORES CUANDO HAY UN CAMBIO DE ESTADO
                    if (this.processTypes[k] == 'date' || this.processTypes[k] == 'datepast') {
                        // datos.input = {};
                    } else if (this.processTypes[k] == 'datetime') {
                        // datos.input = {};
                    } else if (this.processTypes[k] == 'time') {
                        // datos.input = {};
                    }
                }
            }
        },

        processAfterChanges(pType, i, result) {
            // console.log('processAfterChanges -> result', pType, i, result)
            // console.log('processAfterChanges -> current_template_error', this.current_template_error)
            if (pType != 'date' && pType != 'datepast' && pType != 'string' && pType != 'datetime' && pType != 'datetimefree' && pType != 'datefree' && pType != 'time' && pType != 'number' && pType != 'barcode' && pType != 'state' && pType != 'datetimepast' && pType != 'previous_labels' && !this.current_template_error) {
                if (this.currentStep + 1 < this.data.length) {
                    this.currentStep++
                }
            }
            if (!this.current_template_error && (this.$mq != 'portrait' || ['barcode', 'date', 'datepast', 'datetime', 'datetimepast', 'previous_labels'].includes(pType))) {
                this.updatePreview(i)
            }
        },

        changeStep(goTo) {
            if (goTo == 1) {
                this.loadRelatedLabels(this.processData.selectedState.id)
                this.current_error = false
            }

            // console.log('changeStep -> goTo,current_template_error', goTo, this.current_template_error)
            let isNext = this.currentStep + 1 == goTo
            let item = isNext ? this.data[this.currentStep] : this.data[goTo]
            log('changeStep', item)
            // SPECIAL CASES
            if (item.type == 'barcode' && item.result == '') {
                log('error barcode 0')
                this.$snackbar.error({
                    message: i18n.t('label.barcode.error_format'),
                    closeable: true
                })
            }
            // COMMON FLOW
            else if ((!this.current_template_error && !this.current_error && !this.blockNextButton) || !isNext) {
                // console.log('changeStep -> item', item)
                let result = item.result
                let validResult = item.optional || (result && result != null && result != '')
                let showError = validResult

                if (this.label.blocks && this.label.blocks.length > 0) {
                    let bBlockFound = false
                    this.label.blocks.forEach((block) => {
                        console.log('CHECK BLOCKS', block, item)
                        let blockValue = moment(block.value, 'YYYY-MM-DD').isValid() ? moment(block.value, 'YYYY-MM-DD').format('DD/MM/YYYY') : block.value
                        if (block.keyname == item.keyname && (item.result == blockValue || item.result.includes(blockValue))) {
                            bBlockFound = true
                        }
                    })

                    if (bBlockFound) {
                        validResult = false
                        this.$snackbar.error({
                            message: i18n.t('label.product_blocked'),
                            closeable: true
                        })
                    }
                }

                if (validResult) {
                    if (item.type == 'datepast') {
                        var minDate = moment(item.minDate, 'DD/MM/YYYY')
                        var maxDate = moment(item.maxDate, 'DD/MM/YYYY')
                        var date = moment(item.result, 'DD/MM/YYYY')
                        // console.log(item, date.format('DD/MM/YYYY'), minDate.format('DD/MM/YYYY'), maxDate.format('DD/MM/YYYY'))
                        validResult = date.isBetween(minDate, maxDate) || date.isSame(minDate) || date.isSame(maxDate) || typeof item.minDate === 'undefined'

                        if (!validResult) {
                            item.result = ''
                            this.oParams[item.keyname] = ''
                            this.$snackbar.error({
                                message: i18n.t('tooltip.print_date_out_range'),
                                closeable: true
                            })
                            showError = false
                            this.updatePreview()
                        }
                    } else if (item.type == 'barcode') {
                        var format = item.rules && item.rules.vformat ? item.rules.vformat : 'DD/MM/YYYY 00:00'
                        var equal = item.rules && item.rules.equal ? true : false
                        var barcodeValue = moment(moment(item.result * 1000).format(format), 'DD/MM/YYYY HH:mm')
                        var currentTimeValue = moment(this.print_date.format(format), 'DD/MM/YYYY HH:mm')
                        // !moment(item.result * 1000).isAfter(moment())
                        showError = false
                        if (!(moment(item.result * 1000).isValid() && item.result.toString().length == 10)) {
                            log('ERROR BARCODE 1')
                            validResult = false
                            this.$snackbar.error({
                                message: i18n.t('label.barcode.error_format'),
                                closeable: true
                            })
                        } else if ((equal ? !barcodeValue.isAfter(currentTimeValue) : !barcodeValue.isSameOrAfter(currentTimeValue)) && !(item.rules && item.rules.p)) {
                            log('ERROR BARCODE 2')
                            validResult = false
                            this.$snackbar.error({
                                message: i18n.t('label.barcode.error'),
                                closeable: true
                            })
                        } else if ((equal ? barcodeValue.isAfter(currentTimeValue) : barcodeValue.isSameOrAfter(currentTimeValue)) && item.rules && !item.rules.f && item.rules.p) {
                            log('ERROR BARCODE 3', barcodeValue.isSameOrAfter(currentTimeValue), item.rules && !item.rules.f && item.rules.p)
                            validResult = false
                            this.$snackbar.error({
                                message: i18n.t('label.barcode.error'),
                                closeable: true
                            })
                        }
                    }
                }

                if (this.currentStep != goTo && goTo >= 0 && ((this.currentStep < goTo && validResult && isNext) || this.currentStep > goTo)) {
                    this.currentStep = goTo
                }

                if (!validResult && showError) {
                    this.$snackbar.error({
                        message: 'Debes indicar ' + this.data[this.currentStep].title + ' para continuar',
                        closeable: true
                    })
                } else {
                    // console.log('UPDATE PREVIEW')
                    this.updatePreview()
                }
            }
        },

        blockNext(status) {
            this.blockNextButton = status
        },

        print() {
            // if (!this.printerAvailable) {
            //     var oError = this.$store.getters['printer/getStatusErrorPrinter']
            //     this.$snackbar.error({ message: i18n.t(oError.msg, { s: oError.code }), closeable: true })
            //     return false
            // }
            if (this.checkingPrinter && !this.digitalState) {
                this.$snackbar.error({
                    message: i18n.t('config.printer.errors.busy2'),
                    closeable: true
                })
                return false
            }

            if (this.data[this.data.length - 1].result == '') {
                // if user does not change quantity, check if label has default value for quantity
                this.data[this.data.length - 1].result = typeof this.label.quantity !== 'undefined' && this.label.quantity ? this.label.quantity : '1'
            }

            // print labels
            this.$emit('print', { ...this })

            this.iStateCompleted++

            // only for collections
            if (this.states.length > 1) {
                // update state
                this.$store.commit('label/referenceStateCompleted', this.label)

                // update current quantity
                this.label.currentQuantity = this.data[this.data.length - 1].result
                this.$store.commit('label/referenceStateCurrentQuantity', this.label)
            }

            if (this.states.length > this.iStateCompleted) {
                let collections = this.$store.getters['label/getCollections']

                for (let key in this.states) {
                    let state = this.states[key]

                    // get first state that is not completed
                    if (!collections[state.collectionID].references[state.referenceID].states[state.stateID].completed) {
                        this.iStateIndex = key
                        break
                    }
                }
            }

            if (this.states.length <= this.iStateCompleted) {
                // this.bShowStates = false;
                // this.$emit('unselect');
                this.cancel(true)
                this.$destroy()
            } else {
                // console.log('generate process second label ...')
                this.generateProcess()
            }
        },

        generateProcess(firstTime) {
            var firstTime = typeof firstTime !== 'undefined' ? firstTime : true

            this.$bar.reset()
            this.$bar.hide()

            var self = this
            const serverTimeDifference = self.$store.getters['getServerTimeDifference']

            this.label = { ...this.states[this.iStateIndex] }
            var label = this.label

            this.productName = this.label.name
            this.processData = {
                label: this.label,
                selectedState: this.selectedState
            }

            if (this.labelStateSelected != '' && typeof this.label.states[this.labelStateSelected] !== 'undefined') {
                self.processData.selectedState = {
                    id: this.labelStateSelected,
                    name: this.label.states[this.labelStateSelected].name,
                    digital: this.label.states[this.labelStateSelected].digital
                }
            }

            if (this.type !== 'timerproduct') {
                // console.log('SELECTEDSTATE', this.selectedState)
                var selectedState =
                    self.processData && self.processData.selectedState && self.processData.selectedState.id
                        ? self.processData.selectedState
                        : {
                              id: label.states[Object.keys(label.states)[0]].id,
                              name: label.states[Object.keys(label.states)[0]].name,
                              digital: label.states[Object.keys(label.states)[0]].digital
                          }
            } else {
                var selectedState = {
                    id: label.states[Object.keys(label.states)[0]].id,
                    name: label.states[Object.keys(label.states)[0]].name,
                    digital: label.states[Object.keys(label.states)[0]].digital
                }
            }

            this.digitalState = selectedState.digital == 1

            // console.log(selectedState)
            var format = self.$store.getters['printer/getTemplates'](label.states[selectedState.id].format)
            // console.log('format --> ', JSON.parse(JSON.stringify(format)))
            if (this.currentPrinter && format.printer_id != this.getPrinters[this.currentPrinter].model) {
                // EL PRODUCTO APUNTA A UN TEMPLATE QUE NO ES EL DE LA IMPRESORA SELECCIONADA
                var templates = this.$store.getters['printer/getTemplatesByPrinterModel'](this.getPrinters[this.currentPrinter].model)
                for (var t in templates) {
                    var tpl = templates[t]
                    if (tpl.parent_id == format.id) {
                        format = Object.assign({}, tpl)
                    }
                }
            }

            // console.log('format --> ', JSON.parse(JSON.stringify(format)))
            // console.log(format)

            self.data = []
            self.processTypes = []
            self.currentStep = 0
            self.oParams = {}

            const categories = self.$store.getters['label/getCategories']
            self.oParams.category = categories[self.label.category_id] ? categories[self.label.category_id].name : ''

            if (this.type !== 'timerproduct') {
                self.processTypes.push('state')
                self.data.push({
                    tags: label.states,
                    title: i18n.t('label.popup.process.state'),
                    // message: i18n.t('label.popup.process.state_msg'),
                    result: selectedState ? selectedState.name : '',
                    selected: selectedState ? selectedState.id : '',
                    keyname: 'state'
                })
            }

            if (format && format.fields) {
                for (var i in format.fields) {
                    var step = format.fields[i]
                    self.processTypes.push(step.type)
                    log(JSON.parse(JSON.stringify(step)))
                    if (step.type == 'date') {
                        var maxDate = undefined

                        // if(self.data[0].selected != '' && self.processTypes[0] == 'state'){
                        //   maxDate = moment().add(label.states[self.data[0].selected].time, 'minutes').format('D/M/YYYY');
                        // }

                        self.data.push({
                            title: step.name,
                            result: '',
                            input: {},
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: moment().add(serverTimeDifference, 'minutes').format('DD/MM/YYYY'),
                            maxDate: maxDate,
                            rules: step.rules
                        })
                    } else if (step.type == 'datepast' || step.type == 'datetimepast') {
                        var minDate = undefined
                        var maxDate = moment().add(serverTimeDifference, 'minutes').format('DD/MM/YYYY')

                        if (step.keyname == 'dflevedacao') {
                            minDate = moment().add(serverTimeDifference, 'minutes').subtract(4, 'd').format('DD/MM/YYYY')
                            maxDate = moment().add(serverTimeDifference, 'minutes').subtract(2, 'd').format('DD/MM/YYYY')
                        } else if (step.keyname == 'dftpambiente') {
                            minDate = moment().add(serverTimeDifference, 'minutes').subtract(6, 'd').format('DD/MM/YYYY')
                            maxDate = moment().add(serverTimeDifference, 'minutes').subtract(2, 'd').format('DD/MM/YYYY')
                        }

                        if (step.keyname == 'dflevedacao0') {
                            minDate = moment().add(serverTimeDifference, 'minutes').subtract(2, 'd').format('DD/MM/YYYY')
                            maxDate = moment().add(serverTimeDifference, 'minutes').subtract(0, 'd').format('DD/MM/YYYY')
                        } else if (step.keyname == 'dftpambiente0') {
                            minDate = moment().add(serverTimeDifference, 'minutes').subtract(5, 'd').format('DD/MM/YYYY')
                            maxDate = moment().add(serverTimeDifference, 'minutes').subtract(0, 'd').format('DD/MM/YYYY')
                        }

                        self.data.push({
                            title: step.name,
                            result: '',
                            input: {},
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: minDate,
                            maxDate: maxDate,
                            rules: step.rules
                        })
                    } else if (step.type == 'datetime') {
                        var maxDate = undefined

                        self.data.push({
                            title: step.name,
                            result: '',
                            date: {},
                            time: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: moment().add(serverTimeDifference, 'minutes').format('DD/MM/YYYY'),
                            maxDate: maxDate,
                            rules: step.rules
                        })
                    } else if (step.type == 'datetimefree') {
                        var maxDate = undefined
                        var minDate = undefined
                        self.data.push({
                            title: step.name,
                            result: '',
                            date: {},
                            time: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: minDate,
                            maxDate: maxDate,
                            rules: step.rules
                        })
                    } else if (step.type == 'datefree') {
                        var maxDate = undefined
                        var minDate = undefined
                        self.data.push({
                            title: step.name,
                            result: '',
                            date: {},
                            time: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: minDate,
                            maxDate: maxDate,
                            rules: step.rules
                        })
                    } else {
                        self.data.push({
                            title: step.name,
                            result: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            rules: step.rules
                        })
                    }
                }
            }
            self.processTypes.push('quantity')
            self.data.push({
                title: i18n.t('label.popup.process.quantity'),
                result: '',
                max: self.$store.getters['getMaxLabelsPrint'],
                // message: i18n.t("label.popup.process.quantity_msg"),
                keyname: 'quantity'
            })

            if (Object.keys(label.states).length <= 1 && this.type != 'timerproduct') {
                self.currentStep++
                // self.processTypes.shift();
            }
            // for (var o in self.data) {
            //   var dt = self.data[o];
            //
            //   if (dt.result != "" && o == 0) {
            //     self.currentStep++;
            //   } else if (dt.result != "" && self.data[o - 1].result != "") {
            //     self.currentStep++;
            //   }
            // }

            self.processData.selectedState = selectedState
            this.validatePrinterConfiguration(false)

            // only first time, move to next step if number of label states are > 1 if is equal this step is moved by if of up
            // console.log('GenerateProcess - labelStateSelected: ', this.labelStateSelected)
            if (!this.blockNextButton && !this.current_error && Object.keys(label.states).length > 1 && this.labelStateSelected != '' && typeof this.label.states[this.labelStateSelected] !== 'undefined' && firstTime && this.type != 'timerproduct') {
                // console.log('GenerateProcess entroooo')
                self.currentStep++
            }

            // reset selected label state
            this.labelStateSelected = ''

            // get date by timezone not from device
            let date = moment()
            if (!this.bUpdatedPrintDate) {
                try {
                    const options = {
                        timeZone: this.timezone,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }
                    const copy = moment(date)

                    log('1', date.format('DD/MM/YYYY HH:mm'))

                    const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                    const objectDate = {}
                    for (const item of dateTimeFormat) {
                        objectDate[item.type] = item.value
                    }
                    const dateString = `${objectDate['day'].padStart(2, '0')}/${objectDate['month'].padStart(2, '0')}/${objectDate['year']} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                    date = moment(dateString, 'DD/MM/YYYY HH:mm')

                    log('2', date.format('DD/MM/YYYY HH:mm'))
                    log('3', date.diff(copy, 'minutes'))
                    log('4', serverTimeDifference)

                    if (date.diff(copy, 'minutes') != serverTimeDifference) {
                        const difference = serverTimeDifference - date.diff(copy, 'minutes')
                        date = date.add(difference, 'minutes')
                    }
                } catch (error) {
                    logError(error)
                }
            }

            log(date.format('DD/MM/YYYY HH:mm'))

            this.print_date = this.bUpdatedPrintDate ? this.print_date : date
            this.init_date = date

            if (!this.current_template_error || !this.firstTime) {
                self.updatePreview()
            }
        },

        updatePreview() {
            var self = this
            const serverTimeDifference = this.$store.getters['getServerTimeDifference']

            var printer = this.$store.getters['printer/getPrinter'] ? this.$store.getters['printer/getPrinter'] : this.$store.getters['login/getPrinter']
            //   console.log('updatePreview -> printer', printer)
            var config = Object.keys(this.$store.getters['printer/getConfig']).length > 0 ? this.$store.getters['printer/getConfig'] : printer.config

            var label = this.label
            var note = label.note ? label.note : ''
            self.oParams['note'] = note
            // console.log('updatePreview -> self.oParams', Object.assign({}, self.oParams))
            // console.log('print -> label', label)
            var states = label.states ? label.states : false
            var selState = this.processData.selectedState.id ? this.processData.selectedState.id : false
            var stateMsg = label.states[selState].msg
            var stateInfo = label.states[selState].info != null && label.states[selState].info != 'null' ? label.states[selState].info : {}

            var time = states[selState] && !isNaN(states[selState].time) ? parseInt(states[selState].time) : 0
            var preptime = states[selState] && !isNaN(states[selState].preptime) ? parseInt(states[selState].preptime) : 0

            var format = self.$store.getters['printer/getTemplates'](label.states[selState].format)
            // console.log(label.states[selState].format, selState)
            // console.log('updatePreview -> format', format)
            if (format) {
                var previewStructure = JSON.parse(format.preview)
                var newPreviewStructure = format.realistic_preview ? JSON.parse(format.realistic_preview) : false

                // TODO REVIEW EL GET TEMPLATE
                // var format = self.$store.getters['printer/getFormatFromTpl'](config.template_id, label.states[oProduct.selectedState.id].format);

                var formatValues = format ? JSON.parse(format.data) : []
                //   console.log('updatePreview -> formatValues', formatValues)

                // --- Set de valores que ya conocemos

                self.oParams['state'] = states[selState].name
                self.next_state = states[selState].next
                var oLocation = self.$store.getters['loginUser/getLocalLocation']
                // console.log('LOCATION ->', oLocation)
                self.oParams['account'] = oLocation.account_name
                self.oParams['location'] = oLocation.name
                self.oParams['city'] = oLocation.city
                self.oParams['address'] = oLocation.address
                self.oParams['loc_state'] = oLocation.state
                self.oParams['cp'] = oLocation.cp
                self.oParams['quantity'] = self.data[self.data.length - 1].result.trim() != '' ? self.data[self.data.length - 1].result : 1

                for (var key in stateInfo) {
                    self.oParams[key] = stateInfo[key]
                }

                // SETTING EMPLOYEE
                var emp_id = self.$store.getters['loginUser/getLocalEmployee']
                //   console.log('updatePreview -> emp_id', emp_id)

                if (emp_id) {
                    var emp = this.$store.getters['employee/getEmployee'](emp_id)
                    // console.log('updatePreview -> emp', emp)
                    self.oParams['employee'] = emp.initials
                } else {
                    self.oParams['employee'] = ''
                }

                // Fecha de impresion de etiqueta
                var printDate = this.print_date
                if(self.tpRound && self.type != 'timerproduct') {
                    printDate = printDate.roundTo('minute', self.tpRound)
                }
                if (!self.autoDateCalc) {
                    self.oParams['print_date'] = printDate.format('DD/MM/YYYY HH:mm')
                    self.oParams['print_date_d1'] = printDate.isoWeekday() == 1 ? 'x' : ' '
                    self.oParams['print_date_d2'] = printDate.isoWeekday() == 2 ? 'x' : ' '
                    self.oParams['print_date_d3'] = printDate.isoWeekday() == 3 ? 'x' : ' '
                    self.oParams['print_date_d4'] = printDate.isoWeekday() == 4 ? 'x' : ' '
                    self.oParams['print_date_d5'] = printDate.isoWeekday() == 5 ? 'x' : ' '
                    self.oParams['print_date_d6'] = printDate.isoWeekday() == 6 ? 'x' : ' '
                    self.oParams['print_date_d7'] = printDate.isoWeekday() == 7 ? 'x' : ' '
                    self.oParams['print_date_day'] = printDate.format('DD/MM/YYYY')
                    self.oParams['print_date_time'] = printDate.format('HH:mm')
                    self.oParams['print_date_timestamp'] = parseInt(printDate.valueOf() / 1000).toString()
                } else {
                    self.oParams['print_date_d1'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 1 ? 'x' : ' '
                    self.oParams['print_date_d2'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 2 ? 'x' : ' '
                    self.oParams['print_date_d3'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 3 ? 'x' : ' '
                    self.oParams['print_date_d4'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 4 ? 'x' : ' '
                    self.oParams['print_date_d5'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 5 ? 'x' : ' '
                    self.oParams['print_date_d6'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 6 ? 'x' : ' '
                    self.oParams['print_date_d7'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 7 ? 'x' : ' '
                    self.oParams['print_date_day'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
                    self.oParams['print_date_time'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').format('HH:mm')
                    self.oParams['print_date_timestamp'] = parseInt(moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').valueOf() / 1000).toString()
                }

                var currentDay = moment(moment().add(serverTimeDifference, 'minutes').format('DD/MM/YYYY 00:00'), 'DD/MM/YYYY HH:mm')
                self.oParams['currentday'] = currentDay.format('DD/MM/YYYY 00:00')
                self.oParams['currentday_day'] = currentDay.format('DD/MM/YYYY')
                self.oParams['currentday_time'] = currentDay.format('00:00')
                self.oParams['currentday_timestamp'] = parseInt(currentDay.valueOf() / 1000).toString()

                var currentTime = moment(moment().add(serverTimeDifference, 'minutes').format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm')
                self.oParams['currenttime'] = currentTime.format('DD/MM/YYYY HH:mm')
                self.oParams['currenttime_day'] = currentTime.format('DD/MM/YYYY')
                self.oParams['currenttime_time'] = currentTime.format('HH:mm')
                self.oParams['currenttime_timestamp'] = parseInt(currentTime.valueOf() / 1000).toString()

                // Fecha de cáculo de caducidades
                var initDate = self.oParams['initdate'] ? moment(self.oParams['initdate'], 'DD/MM/YYYY HH:mm') : !self.autoDateCalc ? printDate : moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm')

                // Caducidad secundaria
                const cad2a = self.formatTimeToMax(time)
                let aux = !self.autoDateCalc ? moment(initDate).add(cad2a.result, cad2a.format) : moment(self.oParams['date'], 'DD/MM/YYYY HH:mm') // Print date + time
                if(self.tpRound && self.type != 'timerproduct') {
                    aux = aux.roundTo('minute', self.tpRound)
                }
                self.oParams['date'] = aux.format('DD/MM/YYYY HH:mm')
                self.oParams['date_day'] = aux.format('DD/MM/YYYY')
                self.oParams['date_time'] = aux.format('HH:mm')
                self.oParams['date_timestamp'] = parseInt(aux.valueOf() / 1000).toString()

                self.oParams['date_raw'] = aux.format('DD/MM/YYYY HH:mm')
                self.oParams['date_raw_day'] = aux.format('DD/MM/YYYY')
                self.oParams['date_raw_time'] = aux.format('HH:mm')
                self.oParams['date_raw_timestamp'] = parseInt(aux.valueOf() / 1000).toString()

                // Caducidad de preparacion (?)
                const freezingTime = self.formatTimeToMax(preptime)
                aux = moment(initDate).add(freezingTime.result, freezingTime.format) // Print date + preptime
                if(self.tpRound && self.type != 'timerproduct') {
                    aux = aux.roundTo('minute', self.tpRound)
                }
                self.oParams['prep_date'] = aux.format('DD/MM/YYYY HH:mm')
                self.oParams['prep_date_day'] = aux.format('DD/MM/YYYY')
                self.oParams['prep_date_time'] = aux.format('HH:mm')
                self.oParams['prep_date_timestamp'] = parseInt(aux.valueOf() / 1000).toString()

                // --- Set de valores que han de ser calculados pero que no los introduce
                //     el usuario, por tanto no estan en el process de por si
                for (var field in formatValues) {
                    var value = formatValues[field]

                    // si son formatValues y estan entre [ ]
                    const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                    let m

                    while ((m = regex.exec(value)) !== null) {
                        // This is necessary to avoid infinite loops with zero-width matches
                        if (m.index === regex.lastIndex) {
                            regex.lastIndex++
                        }

                        // The result can be accessed through the `m`-variable.
                        m.forEach((match, groupIndex) => {
                            match = match.replace(/[\{\}\[\]]/gi, '')
                            if (match != '' && !Object.keys(self.oParams).includes(match)) {
                                self.oParams[match] = ''
                            }
                        })
                    }

                    // var res = regex.exec(value)
                    // var index = ''
                    // while (res != null && res.length > 0) {
                    //     var index = res[0].replace(/[\{\}\[\]]/gi, '')
                    //     console.log(res[0], index)
                    //     if (index != '' && !Object.keys(self.oParams).includes(index)) {
                    //         self.oParams[index] = ''
                    //     }

                    //     value = value.replace(res[0], self.oParams[index])
                    //     res = regex.exec(value)
                    //     console.log(value, res, index, self.oParams[index])
                    // }
                }

                //   console.log('--- Setted variables')
                //   console.log(self.oParams)

                // --- Format data from process, and create auxiliary info (date_da, date_time...)
                var oProcess = JSON.parse(JSON.stringify(self.data))
                var bCaducidadIsBarcode = false
                for (var i in oProcess) {
                    var step = oProcess[i]
                    // console.log('updatePreview SETP: ', Object.assign({}, step))
                    if (step.keyname == 'quantity') {
                        self.oParams[step.keyname] = step.result != '' ? step.result : '1'
                        self.num_copies = step.result != '' ? parseInt(step.result) : 1
                    } else if (step.type == 'date' || step.type == 'datetime' || step.type == 'datepast' || step.type == 'datetimefree' || step.type == 'datefree' || step.type == 'barcode' || step.type == 'datetimepast') {
                        if ((step.type == 'datetime' || step.type == 'datetimefree' || step.type == 'datefree' || step.type == 'datetimepast') && step.result && step.result.trim() !== '') {
                            // console.log(step.result)
                            step.result = moment(step.result, 'DD/MM/YYYY HH:mm')
                        } else if (step.type == 'barcode') {
                            if (step.keyname == 'caducidad') bCaducidadIsBarcode = true

                            if (step.result && step.result.trim() !== '') {
                                self.oParams[step.keyname] = step.result
                                if (step.result.toString().length == 10) {
                                    step.result = moment(step.result * 1000)
                                } else {
                                    step.result = moment(false)
                                }
                            }
                        } else if (step.result && step.result.trim() !== '') {
                            // TODO CHECK IF THIS IS CORRECT
                            step.result = moment(step.result, 'DD/MM/YYYY HH:mm').endOf('day')
                            // step.result = moment(step.result, 'DD/MM/YYYY HH:mm')
                        }

                        // We create different variables (date, date_day...)
                        // console.log(step.keyname, step.result)
                        let aux = step.result
                        if (aux && aux.isValid()) {
                            if (step.type !== 'barcode') self.oParams[step.keyname] = aux.format('DD/MM/YYYY HH:mm')
                            else self.oParams[step.keyname + '_datetime'] = aux.format('DD/MM/YYYY HH:mm')

                            self.oParams[step.keyname + '_day'] = aux.format('DD/MM/YYYY')
                            self.oParams[step.keyname + '_time'] = aux.format('HH:mm')
                            self.oParams[step.keyname + '_timestamp'] = parseInt(aux.valueOf() / 1000).toString()
                            self.oParams[step.keyname + '_d1'] = aux.isoWeekday() == 1 ? 'x' : ' '
                            self.oParams[step.keyname + '_d2'] = aux.isoWeekday() == 2 ? 'x' : ' '
                            self.oParams[step.keyname + '_d3'] = aux.isoWeekday() == 3 ? 'x' : ' '
                            self.oParams[step.keyname + '_d4'] = aux.isoWeekday() == 4 ? 'x' : ' '
                            self.oParams[step.keyname + '_d5'] = aux.isoWeekday() == 5 ? 'x' : ' '
                            self.oParams[step.keyname + '_d6'] = aux.isoWeekday() == 6 ? 'x' : ' '
                            self.oParams[step.keyname + '_d7'] = aux.isoWeekday() == 7 ? 'x' : ' '

                            for (let i = 0; i < 4; i++) {
                                let day = i18n.t('calendar.days')[aux.day()].toUpperCase()
                                if (i > 0) {
                                    self.oParams[step.keyname + '_wd' + i] = day.slice(0, i)
                                } else {
                                    self.oParams[step.keyname + '_wd'] = day
                                }
                            }
                        } else if (!self.autoDateCalc) {
                            if (step.type !== 'barcode' || step.result == '') self.oParams[step.keyname] = ''
                            else self.oParams[step.keyname + '_datetime'] = ''

                            self.oParams[step.keyname + '_day'] = ''
                            self.oParams[step.keyname + '_time'] = ''
                            self.oParams[step.keyname + '_timestamp'] = ''
                            self.oParams[step.keyname + '_d1'] = ' '
                            self.oParams[step.keyname + '_d2'] = ' '
                            self.oParams[step.keyname + '_d3'] = ' '
                            self.oParams[step.keyname + '_d4'] = ' '
                            self.oParams[step.keyname + '_d5'] = ' '
                            self.oParams[step.keyname + '_d6'] = ' '
                            self.oParams[step.keyname + '_d7'] = ' '

                            for (let i = 0; i < 4; i++) {
                                if (i > 0) {
                                    self.oParams[step.keyname + '_wd' + i] = ' '
                                } else {
                                    self.oParams[step.keyname + '_wd'] = ' '
                                }
                            }
                        }
                    } else if (step.keyname == 'amasada') {
                        self.oParams['num_amasada'] = 1
                        self.oParams[step.keyname] = step.result
                    } else {
                        // We set any other value in their position and result
                        self.oParams[step.keyname] = step.result
                    }
                }

                //   console.log('--- Formatted process variables')
                //   console.log(self.oParams)

                // --- Se calculan las caducidades y se hacen las debidas validaciones

                // Auxiliary vars
                let cadPrimaria = false
                if (bCaducidadIsBarcode) {
                    cadPrimaria = self.oParams['caducidad'] && self.oParams['caducidad_datetime'] ? moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm') : false
                } else {
                    cadPrimaria = self.oParams['caducidad'] && moment(self.oParams['caducidad'], 'DD/MM/YYYY HH:mm').isValid() ? moment(self.oParams['caducidad'], 'DD/MM/YYYY HH:mm') : false
                }

                if (cadPrimaria) {
                    self.oParams['caducidad_day'] = cadPrimaria.format('DD/MM/YYYY')
                    self.oParams['caducidad_time'] = cadPrimaria.format('HH:mm')
                    self.oParams['caducidad_timestamp'] = parseInt(cadPrimaria.valueOf() / 1000).toString()
                } else if (!bCaducidadIsBarcode) {
                    self.oParams['caducidad'] = ''
                    self.oParams['caducidad_day'] = ''
                    self.oParams['caducidad_time'] = ''
                    self.oParams['caducidad_timestamp'] = ''
                }

                // let cadSecundaria = moment(self.oParams['date']);
                // let cadPreparacion = moment(self.oParams['prep_date']);
                let cadSecundaria = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm')
                let cadPreparacion = moment(self.oParams['prep_date'], 'DD/MM/YYYY HH:mm')

                //   console.log('cadPrimaria + cadSecundaria + cadPreparacion')
                //   console.log(cadPrimaria)
                //   console.log(cadSecundaria)
                //   console.log(cadPreparacion)

                // Si el producto caduca antes que la cad. secundaria, ponemos la cad primaria como caducidad
                if (cadPrimaria && cadPrimaria.isBefore(cadSecundaria)) {
                    self.oParams['date'] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                    self.oParams['date_day'] = cadPrimaria.format('DD/MM/YYYY')
                    self.oParams['date_time'] = cadPrimaria.format('HH:mm')
                    self.oParams['date_timestamp'] = parseInt(cadPrimaria.valueOf() / 1000).toString()
                }
                // Lo mismo con el preptime
                if (cadPrimaria && cadPrimaria.isBefore(cadPreparacion)) {
                    self.oParams['date'] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                    self.oParams['date_day'] = cadPrimaria.format('DD/MM/YYYY')
                    self.oParams['date_time'] = cadPrimaria.format('HH:mm')
                    self.oParams['date_timestamp'] = parseInt(cadPrimaria.valueOf() / 1000).toString()

                    // No tiene sentido un t. de preparacion si ya ha caducado, asi que lo quitamos
                    // self.oParams['prep_date'] = '-'
                    // self.oParams['prep_date_day'] = '-'
                    // self.oParams['prep_date_time'] = '-'

                    self.$snackbar.error({
                        message: i18n.t('tooltip.print_invalid_date'),
                        closeable: true
                    })
                    self.current_error = true
                } else {
                    self.current_error = false
                }

                // It only applies at next state
                // Si la cad. secundaria calculada es anterior a la fecha de impresion, es que ha caducado

                // console.error('initDate', initDate.format('DD/MM/YYYY HH:mm'))
                // console.error('cadSecundaria', cadSecundaria.format('DD/MM/YYYY HH:mm'))
                if (cadSecundaria.isSameOrBefore(initDate) || cadSecundaria.isSameOrBefore(currentTime)) {
                    self.oParams['date'] = self.$t('label.expired')
                    self.oParams['date_day'] = self.$t('label.expired')
                    self.oParams['date_time'] = '--'
                    self.oParams['date_timestamp'] = ''
                }

                // Si el estado tiene un mensaje definido, lo mostramos
                if (stateMsg) {
                    self.oParams['date'] = stateMsg
                    self.oParams['date_day'] = stateMsg
                    self.oParams['date_time'] = '--'
                    self.oParams['date_timestmap'] = ''
                }

                //   console.log('--- Calc de logicas generales (Caducado...)')
                //   console.log(self.oParams)

                // --- Calc de valores custom de cada restaurante
                for (var key in self.oParams) {
                    var val = self.oParams[key]
                    let keyUsoDate = false
                    if (key.match(/usodate/)) {
                        keyUsoDate = key
                        key = 'usodate'
                    }
                    // console.log('KEY ->', key, keyUsoDate)
                    switch (key) {
                        // PIZZA HUT HACK -----------------------
                        case 'cad_sec_mesa':
                        case 'cad_sec_mesa1':
                            if (initDate.isSame(cadPrimaria, 'day')) {
                                self.oParams[key] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                            } else {
                                self.oParams[key] = initDate.format('DD/MM/YYYY') + i18n.t('label.print.config.close_day')
                            }
                            break

                        case 'cad_sec_mesa2':
                            if (cadSecundaria.isSame(cadPrimaria, 'day') || cadSecundaria.isAfter(cadPrimaria, 'day')) {
                                self.oParams[key] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                            } else {
                                self.oParams[key] = cadSecundaria.format('DD/MM/YYYY') + i18n.t('label.print.config.close_day')
                            }
                            break
                        case 'caducidad2': //SALSA TOMATE - CADUCIDAD 1ª ESPECIAS
                            var stepCad2 = self.data.filter((step) => {
                                // console.log(index, step)
                                return step.keyname == 'caducidad2'
                            })
                            stepCad2 = stepCad2[0] ? stepCad2[0] : false
                            let caducidad2 = stepCad2 && stepCad2.type == 'barcode' ? moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm') : moment(self.oParams['caducidad2'], 'DD/MM/YYYY HH:mm')
                            // console.log('Caducidad2 - ', cadPrimaria.valueOf(), caducidad2.valueOf(), cadSecundaria.valueOf())
                            if (cadPrimaria.valueOf() > caducidad2.valueOf() && (caducidad2.valueOf() < cadSecundaria.valueOf() || (stateMsg && caducidad2.valueOf() < moment(cadSecundaria.format('DD/MM/YYYY 23:59'), 'DD/MM/YYYY HH:mm').valueOf()))) {
                                cadSecundaria = caducidad2
                                self.oParams['date'] = caducidad2.format('DD/MM/YYYY HH:mm')
                                self.oParams['date_day'] = caducidad2.format('DD/MM/YYYY')
                                self.oParams['date_time'] = caducidad2.format('HH:mm')
                                self.oParams['date_timestamp'] = parseInt(caducidad2.valueOf() / 1000).toString()
                            }
                            if (cadPrimaria && cadPrimaria.isValid() && caducidad2.isValid() && stepCad2.rules && stepCad2.rules.o && stepCad2.rules.pa && stepCad2.rules.v && self.oParams[stepCad2.rules.pa]) {
                                let date_compare = moment()
                                switch (stepCad2.rules.v) {
                                    case 'end_day':
                                        date_compare = moment(printDate.format('DD/MM/YYYY 23:59'), 'DD/MM/YYYY HH:mm')
                                        break

                                    default:
                                        break
                                }
                                switch (stepCad2.rules.o) {
                                    case '<':
                                        self.oParams['caducidad2'] = caducidad2.isBefore(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                    case '<=':
                                        self.oParams['caducidad2'] = caducidad2.isSameOrBefore(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                    case '>':
                                        self.oParams['caducidad2'] = caducidad2.isAfter(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                    case '>=':
                                        self.oParams['caducidad2'] = caducidad2.isSameOrAfter(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                }
                            }
                            break
                        // PIZZA HUT HACK -----------------------
                        case 'df_lev_massa_day':
                            if (moment(self.oParams['dflevedacao'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dflevedacao'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 3, 2, 1, false, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_lev_massa = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_lev_massa_day'] = df_lev_massa.format('DD/MM/YYYY')
                                self.oParams['df_lev_massa_time'] = df_lev_massa.format('HH:mm')
                                self.oParams['df_lev_massa_timestamp'] = parseInt(df_lev_massa.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_lev_massa_day'] = ''
                                self.oParams['df_lev_massa_time'] = ''
                                self.oParams['df_lev_massa_timestamp'] = ''
                            }
                            break
                        case 'df_lev_sides_day':
                            if (moment(self.oParams['dflevedacao'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dflevedacao'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 3, 2, 1, false, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_lev_sides = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_lev_sides_day'] = df_lev_sides.format('DD/MM/YYYY')
                                self.oParams['df_lev_sides_time'] = df_lev_sides.format('HH:mm')
                                self.oParams['df_lev_sides_timestamp'] = parseInt(df_lev_sides.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_lev_sides_day'] = ''
                                self.oParams['df_lev_sides_time'] = ''
                                self.oParams['df_lev_sides_timestamp'] = ''
                            }
                            break
                        case 'df_ta_massa_day':
                            if (moment(self.oParams['dftpambiente'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dftpambiente'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 4, 3, 2, 1, 0.5]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_ta_massa = moment(initDate).add(dfMassa[diff], 'h')
                                log(diff, dfMassa[diff], initDate)

                                self.oParams['df_ta_massa_day'] = df_ta_massa.format('DD/MM/YYYY')
                                self.oParams['df_ta_massa_time'] = df_ta_massa.format('HH:mm')
                                self.oParams['df_ta_massa_timestamp'] = parseInt(df_ta_massa.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_ta_massa_day'] = ''
                                self.oParams['df_ta_massa_time'] = ''
                                self.oParams['df_ta_massa_timestamp'] = ''
                            }
                            break
                        case 'df_ta_sides_day':
                            if (moment(self.oParams['dftpambiente'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dftpambiente'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 2, 1.5, 1, 0.5, 0.25]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_ta_sides = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_ta_sides_day'] = df_ta_sides.format('DD/MM/YYYY')
                                self.oParams['df_ta_sides_time'] = df_ta_sides.format('HH:mm')
                                self.oParams['df_ta_sides_timestamp'] = parseInt(df_ta_sides.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_ta_sides_day'] = ''
                                self.oParams['df_ta_sides_time'] = ''
                                self.oParams['df_ta_sides_timestamp'] = ''
                            }
                            break
                        case 'df_lev_pan_day':
                            if (moment(self.oParams['dflevedacao0'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dflevedacao0'], 'DD/MM/YYYY')
                                let dfMassa = [2.5, 1.5, 1, false, false, false, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_lev_pan = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_lev_pan_day'] = df_lev_pan.format('DD/MM/YYYY')
                                self.oParams['df_lev_pan_time'] = df_lev_pan.format('HH:mm')
                                self.oParams['df_lev_pan_timestamp'] = parseInt(df_lev_pan.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_lev_pan_day'] = ''
                                self.oParams['df_lev_pan_time'] = ''
                                self.oParams['df_lev_pan_timestamp'] = ''
                            }
                            break
                        case 'df_ta_pan_day':
                            if (moment(self.oParams['dftpambiente0'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dftpambiente0'], 'DD/MM/YYYY')
                                let dfMassa = [4, 4, 3, 3, 2, 2, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_ta_pan = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_ta_pan_day'] = df_ta_pan.format('DD/MM/YYYY')
                                self.oParams['df_ta_pan_time'] = df_ta_pan.format('HH:mm')
                                self.oParams['df_ta_pan_timestamp'] = parseInt(df_ta_pan.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_ta_pan_day'] = ''
                                self.oParams['df_ta_pan_time'] = ''
                                self.oParams['df_ta_pan_timestamp'] = ''
                            }
                            break
                        case 'usodate':
                            if ((self.oParams['caducidad_datetime'] && self.oParams['caducidad_datetime'] != '' && self.oParams['caducidad2_datetime'] && self.oParams['caducidad2_datetime'] != '') || (!cadPrimaria && self.oParams['caducidad2_datetime'] && self.oParams['caducidad2_datetime'] != '')) {
                                console.log('ENTRO A USO DATE', self.oParams[keyUsoDate], self.oParams['caducidad_datetime'], self.oParams['caducidad2_datetime'])
                                if (self.oParams['caducidad2_datetime'] && self.oParams['caducidad2_datetime'] != '') {
                                    self.oParams[keyUsoDate] = moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm').add(preptime, 'minutes').format('DD/MM/YYYY HH:mm')
                                } else {
                                    self.oParams[keyUsoDate] = moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm').add(preptime, 'minutes').format('DD/MM/YYYY HH:mm')
                                }
                            } else if (self.oParams['caducidad_datetime'] && typeof self.oParams['caducidad2'] == 'undefined' && self.oParams[keyUsoDate] == '') {
                                console.log('ENTRO A USO DATE CON SOLO caducidad')
                                self.oParams[keyUsoDate] = moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm').add(preptime, 'minutes').format('DD/MM/YYYY HH:mm')
                            } else if (self.oParams[keyUsoDate] != '' && (self.oParams['caducidad_datetime'] == '' || self.oParams['caducidad2_datetime'] == '')) {
                                self.oParams[keyUsoDate] = ''
                            }

                            if (self.oParams[keyUsoDate] != '') {
                                var alseaCad2Calc = moment(printDate.valueOf())
                                var bDateModify = false
                                if (time == 0 && stateMsg != '' && stateMsg != null) {
                                    alseaCad2Calc = moment(alseaCad2Calc.format('DD/MM/YYYY') + ' 23:59:59', 'DD/MM/YYYY HH:mm:ss')
                                    if (alseaCad2Calc.isAfter(moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm')
                                        bDateModify = true
                                    }
                                    if (self.oParams['caducidad_datetime'] && self.oParams['caducidad_datetime'] != '' && alseaCad2Calc.isAfter(moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm')
                                        bDateModify = true
                                    }

                                    self.oParams['a_date_calc'] = !bDateModify ? stateMsg : alseaCad2Calc.format('DD/MM/YYYY HH:mm')
                                    self.oParams['a_date_calc_day'] = alseaCad2Calc.format('DD/MM/YYYY')
                                    self.oParams['a_date_calc_time'] = alseaCad2Calc.format('HH:mm')
                                    self.oParams['a_date_calc_timestamp'] = parseInt(alseaCad2Calc.valueOf() / 1000).toString()
                                    self.oParams['a_date_calc_d1'] = alseaCad2Calc.isoWeekday() == 1 ? 'x' : ' '
                                    self.oParams['a_date_calc_d2'] = alseaCad2Calc.isoWeekday() == 2 ? 'x' : ' '
                                    self.oParams['a_date_calc_d3'] = alseaCad2Calc.isoWeekday() == 3 ? 'x' : ' '
                                    self.oParams['a_date_calc_d4'] = alseaCad2Calc.isoWeekday() == 4 ? 'x' : ' '
                                    self.oParams['a_date_calc_d5'] = alseaCad2Calc.isoWeekday() == 5 ? 'x' : ' '
                                    self.oParams['a_date_calc_d6'] = alseaCad2Calc.isoWeekday() == 6 ? 'x' : ' '
                                    self.oParams['a_date_calc_d7'] = alseaCad2Calc.isoWeekday() == 7 ? 'x' : ' '
                                } else {
                                    const alcad2a = self.formatTimeToMax(time)
                                    alseaCad2Calc = alseaCad2Calc.add(alcad2a.result, alcad2a.format)

                                    if (alseaCad2Calc.isAfter(moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm')
                                    }
                                    if (alseaCad2Calc.isAfter(moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm')
                                    }
                                    self.oParams['a_date_calc'] = alseaCad2Calc.format('DD/MM/YYYY HH:mm')
                                    self.oParams['a_date_calc_day'] = alseaCad2Calc.format('DD/MM/YYYY')
                                    self.oParams['a_date_calc_time'] = alseaCad2Calc.format('HH:mm')
                                    self.oParams['a_date_calc_timestamp'] = parseInt(alseaCad2Calc.valueOf() / 1000).toString()
                                    self.oParams['a_date_calc_d1'] = alseaCad2Calc.isoWeekday() == 1 ? 'x' : ' '
                                    self.oParams['a_date_calc_d2'] = alseaCad2Calc.isoWeekday() == 2 ? 'x' : ' '
                                    self.oParams['a_date_calc_d3'] = alseaCad2Calc.isoWeekday() == 3 ? 'x' : ' '
                                    self.oParams['a_date_calc_d4'] = alseaCad2Calc.isoWeekday() == 4 ? 'x' : ' '
                                    self.oParams['a_date_calc_d5'] = alseaCad2Calc.isoWeekday() == 5 ? 'x' : ' '
                                    self.oParams['a_date_calc_d6'] = alseaCad2Calc.isoWeekday() == 6 ? 'x' : ' '
                                    self.oParams['a_date_calc_d7'] = alseaCad2Calc.isoWeekday() == 7 ? 'x' : ' '
                                }

                                let iMarginTime = keyUsoDate.replace('usodate', '')
                                let bInMarginTime = true
                                if (iMarginTime != '') {
                                    bInMarginTime = printDate.isBefore(moment(self.oParams[keyUsoDate], 'DD/MM/YYYY HH:mm').add(iMarginTime, 'minutes'))
                                }

                                if (printDate.isBefore(moment(self.oParams[keyUsoDate], 'DD/MM/YYYY HH:mm')) || !bInMarginTime || (bDateModify && printDate.isAfter(alseaCad2Calc)) || alseaCad2Calc.isBefore(printDate)) {
                                    self.$snackbar.error({
                                        message: i18n.t('tooltip.print_date_out_range'),
                                        closeable: true
                                    })
                                    self.current_error = true
                                }
                            } else {
                                self.oParams['a_date_calc'] = ''
                                self.oParams['a_date_calc_day'] = ''
                                self.oParams['a_date_calc_time'] = ''
                                self.oParams['a_date_calc_timestamp'] = ''
                                self.oParams['a_date_calc_d1'] = ' '
                                self.oParams['a_date_calc_d2'] = ' '
                                self.oParams['a_date_calc_d3'] = ' '
                                self.oParams['a_date_calc_d4'] = ' '
                                self.oParams['a_date_calc_d5'] = ' '
                                self.oParams['a_date_calc_d6'] = ' '
                                self.oParams['a_date_calc_d7'] = ' '
                            }
                            break
                        default:
                            if (key.match(/ingr.{1,2}date$/gi) && self.oParams[key] != '') {
                                let caducidadIngr = moment(self.oParams[key], 'DD/MM/YYYY HH:mm')
                                if (caducidadIngr.isBefore(printDate)) {
                                    self.$snackbar.error({
                                        message: i18n.t('tooltip.print_date_out_range'),
                                        closeable: true
                                    })
                                    self.current_error = true
                                } else if ((cadPrimaria.valueOf() > caducidadIngr.valueOf() || !cadPrimaria) && caducidadIngr.valueOf() < cadSecundaria.valueOf()) {
                                    cadSecundaria = caducidadIngr
                                    self.oParams['date'] = caducidadIngr.format('DD/MM/YYYY HH:mm')
                                    self.oParams['date_day'] = caducidadIngr.format('DD/MM/YYYY')
                                    self.oParams['date_time'] = caducidadIngr.format('HH:mm')
                                    self.oParams['date_timestamp'] = parseInt(caducidadIngr.valueOf() / 1000).toString()
                                }
                            }
                            break
                    }
                }

                let dateParams = ['print_date', 'currentday', 'currenttime', 'date', 'prep_date']
                dateParams.forEach((date) => {
                    for (let i = 0; i < 4; i++) {
                        aux = moment(self.oParams[date], 'DD/MM/YYYY HH:mm')
                        if (aux.isValid()) {
                            let day = i18n.t('calendar.days')[aux.day()].toUpperCase()
                            if (i > 0) {
                                self.oParams[date + '_wd' + i] = day.slice(0, i)
                            } else {
                                self.oParams[date + '_wd'] = day
                            }
                        } else {
                            // IN CASE INVALID DATE, GENERATE DAY INFO WITH CURRENT DAY
                            let day = i18n.t('calendar.days')[moment().day()].toUpperCase()
                            if (i > 0) {
                                self.oParams[date + '_wd' + i] = day.slice(0, i)
                            } else {
                                self.oParams[date + '_wd'] = day
                            }
                        }
                    }
                })

                self.oParams['product'] = self.label.name

                // console.log('--- Calcs propios de restaurantes')
                // console.log(self.oParams)
                // console.log(formatValues)

                // --- Seteamos los datos que iran al template de la etiqueta
                for (var field in formatValues) {
                    var value = formatValues[field]

                    if (value) {
                        let aux = value

                        let start = aux.indexOf('[') > -1 ? aux.indexOf('[') : aux.indexOf('{')
                        start++
                        let end = aux.indexOf(']') > -1 ? aux.indexOf(']') : aux.indexOf('}')
                        let keyValue = aux.substring(start, end)
                        let oldKeyValue = aux.substring(start, end)

                        if (keyValue) {
                            keyValue = keyValue.replace('[', '').replace(']', '').replace('{', '').replace('}', '')
                            aux = aux.replace('[', '').replace(']', '').replace('{', '').replace('}', '')

                            if (!self.oParams[keyValue]) {
                                self.oParams[keyValue] = aux
                            }
                            let newValue = cleanString(self.oParams[keyValue])
                            // console.log('PREVIEW', keyValue, aux, newValue, oldKeyValue)
                            if (newValue.indexOf(oldKeyValue) != -1) {
                                // if (newValue == oldKeyValue || aux == newValue) {
                                newValue = '' //newValue.replace(oldKeyValue, '')
                                // console.log('PREVIEW', newValue)
                                self.oParams[keyValue] = newValue
                            }
                            aux = aux.replace(keyValue, newValue)
                            // console.log('PREVIEW', keyValue, newValue, aux)
                        }

                        // if print date is updated, update preview

                        if (['print_date', 'print_date_time', 'print_date_day'].includes(keyValue) && self.bUpdatedPrintDate) {
                            aux += ' *'
                            self.calculate += 1
                        }

                        self.printData.push({ k: field, v: cleanString(aux) })
                    } else {
                        self.printData.push({ k: field, v: '' })
                    }
                }

                // -- Mostraremos en el preview los mismos valores que se quieren imprimir
                let preview = {}
                for (var field in formatValues) {
                    var value = formatValues[field]

                    if (value.indexOf('[') != -1) {
                        let start = value.indexOf('[')
                        let end = value.indexOf(']')
                        let keyValue = value.substring(start, end).replace('[', '').replace(']', '')

                        let name = self.$t('label.preview.' + keyValue)
                        if (name.indexOf('label.preview.') != -1) {
                            name = keyValue
                        }

                        if (keyValue != '' && !Object.keys(preview).includes(keyValue) && Object.keys(self.oParams).includes(keyValue)) {
                            preview[keyValue] = {
                                label: name,
                                value: self.oParams[keyValue]
                            }
                        }
                    }
                }

                //   console.log('preview -> ', preview)
                //   console.log('format ->', format)
                //   console.log('previewStructure', previewStructure)

                var currentLength = 4
                var currentRow = -1
                var values = JSON.parse(format.data)
                // console.log('updatePreview -> values', values)
                // console.log('updatePreview -> self.oParams', self.oParams)

                self.previewPrint = []
                previewStructure.forEach((el) => {
                    let key = el[0]
                    let length = el[1]
                    if (currentLength + length > 4) {
                        currentRow++
                        currentLength = 0
                        self.previewPrint[currentRow] = []
                    }
                    if (values[key].match(/[\{\}\[\]]/gi)) {
                        const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                        var val = values[key]
                        var value = val
                        let m
                        var index = ''
                        while ((m = regex.exec(val)) !== null) {
                            // This is necessary to avoid infinite loops with zero-width matches
                            if (m.index === regex.lastIndex) {
                                regex.lastIndex++
                            }

                            // The result can be accessed through the `m`-variable.
                            m.forEach((match, groupIndex) => {
                                index = match.replace(/[\{\}\[\]]/gi, '')
                                // console.log('MATCH: ', match, index, self.oParams[index])
                                value = value.replace(match, self.oParams[index])

                                if (['print_date', 'print_date_time', 'print_date_day'].includes(index) && self.bUpdatedPrintDateVisible) {
                                    value += ' *'
                                }
                            })
                        }

                        // var res = regex.exec(val)
                        // var index = ''
                        // var value = val
                        // while (res != null && res.length > 0) {
                        //     val = value.replace(res[0], '')
                        //     var index = res[0].replace(/[\{\}\[\]]/gi, '')
                        //     value = value.replace(res[0], self.oParams[index])

                        //     if (['print_date', 'print_date_time', 'print_date_day'].includes(index) && self.bUpdatedPrintDateVisible) {
                        //         value += ' *'
                        //     }

                        //     // console.log('KEY -> ', values[key], key, index, val, self.oParams, value)
                        //     res = regex.exec(value)
                        // }
                        // console.log('BARCODE ', self.currentStep)
                        // console.log('BARCODE ', self.data[self.currentStep])

                        var currentStep = self.data.filter((step) => {
                            // console.log(index, step)
                            return step.keyname == index
                        })

                        // console.log(currentStep)

                        self.previewPrint[currentRow].push({
                            v: value,
                            l: length,
                            current: index.split('_')[0] == self.data[self.currentStep].keyname.split('_')[0],
                            barcode: index.match(/_timestamp/gi) || (currentStep[0] && currentStep[0].type == 'barcode') ? true : false
                        })
                    } else {
                        self.previewPrint[currentRow].push({
                            v: values[key],
                            l: length
                        })
                    }
                    currentLength += length
                })
                if (newPreviewStructure) {
                    self.newPreview = true
                    // console.log('VALUES: ', values)
                    newPreviewStructure.fields.forEach((el) => {
                        let key = el.field

                        if (el.f_id && values[el.f_id]) {
                            const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                            var val = values[el.f_id]
                            log('F_ID: ', el.f_id)
                            log('Val: ', val)
                            var value = val

                            if (values[el.f_id].match(/[\{\}\[\]]/gi)) {
                                let m
                                var index = ''
                                while ((m = regex.exec(val)) !== null) {
                                    // This is necessary to avoid infinite loops with zero-width matches
                                    if (m.index === regex.lastIndex) {
                                        regex.lastIndex++
                                    }

                                    // The result can be accessed through the `m`-variable.
                                    m.forEach((match, groupIndex) => {
                                        index = match.replace(/[\{\}\[\]]/gi, '')
                                        // console.log('MATCH: ', match, index, self.oParams[index])
                                        value = value.replace(match, self.oParams[index])
                                        console.log("FORMAT --> el.format", el.format, value)
                                        if (el.format && !moment(value, el.format, true).isValid()) {
                                            value = moment(moment(value, 'DD/MM/YYYY HH:mm').format(el.format), el.format).isValid() ? moment(value, 'DD/MM/YYYY HH:mm').format(el.format) : value
                                        }

                                        if (['print_date', 'print_date_time', 'print_date_day'].includes(index) && self.bUpdatedPrintDateVisible) {
                                            value += ' *'
                                        }
                                    })
                                }
                                el.current = index.split('_')[0] == self.data[self.currentStep].keyname.split('_')[0]
                            }

                            el.value = value
                        } else if (el.value && el.value.match(/[\{\}\[\]]/gi)) {
                            const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                            var val = el.value
                            var value = val
                            // console.log('VAL: ', val, 'VALUE:', value)
                            let m
                            var index = ''
                            while ((m = regex.exec(val)) !== null) {
                                // This is necessary to avoid infinite loops with zero-width matches
                                if (m.index === regex.lastIndex) {
                                    regex.lastIndex++
                                }

                                // The result can be accessed through the `m`-variable.
                                m.forEach((match, groupIndex) => {
                                    index = match.replace(/[\{\}\[\]]/gi, '')
                                    // console.log('MATCH: ', match, index, self.oParams[index])
                                    value = value.replace(match, self.oParams[index])

                                    if (el.format && !moment(value, el.format, true).isValid()) {
                                        value = moment(moment(value, 'DD/MM/YYYY HH:mm').format(el.format)).isValid() ? moment(value, 'DD/MM/YYYY HH:mm').format(el.format) : value
                                    }
                                    // console.log('INDEX:', index, 'MATCH: ', match, 'VALUE:', value)
                                    if (['print_date', 'print_date_time', 'print_date_day'].includes(index) && self.bUpdatedPrintDateVisible) {
                                        value += ' *'
                                    }
                                })
                            }
                            // console.log('BARCODE ', self.currentStep)
                            // console.log('BARCODE ', self.data[self.currentStep])

                            var currentStep = self.data.filter((step) => {
                                // console.log(index, step)
                                return step.keyname == index
                            })

                            // console.log(currentStep)

                            // self.previewPrint[currentRow].push({
                            //     v: value,
                            //     l: length,
                            //     current: index.split('_')[0] == self.data[self.currentStep].keyname.split('_')[0],
                            //     barcode: index.match(/_timestamp/gi) || (currentStep[0] && currentStep[0].type == 'barcode') ? true : false
                            // })
                            el.current = index.split('_')[0] == self.data[self.currentStep].keyname.split('_')[0]
                            el.value = value
                        }
                    })
                    self.newPreviewData = Object.assign({}, newPreviewStructure)
                } else {
                    self.newPreview = false
                }
            }
            // console.log('updatePreview', self.oParams)
            let date = moment()
            if (!this.bUpdatedPrintDate) {
                try {
                    const options = {
                        timeZone: this.timezone,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }
                    const copy = moment(date)

                    log('1', date.format('DD/MM/YYYY HH:mm'))

                    const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                    const objectDate = {}
                    for (const item of dateTimeFormat) {
                        objectDate[item.type] = item.value
                    }
                    const dateString = `${objectDate['day'].padStart(2, '0')}/${objectDate['month'].padStart(2, '0')}/${objectDate['year']} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                    date = moment(dateString, 'DD/MM/YYYY HH:mm')

                    log('2', date.format('DD/MM/YYYY HH:mm'))
                    log('3', date.diff(copy, 'minutes'))
                    log('4', serverTimeDifference)

                    if (date.diff(copy, 'minutes') != serverTimeDifference) {
                        const difference = serverTimeDifference - date.diff(copy, 'minutes')
                        date = date.add(difference, 'minutes')
                    }
                } catch (error) {
                    logError(error)
                }
            }

            this.print_date = this.bUpdatedPrintDate ? this.print_date : date
        },

        condensedView(value) {
            this.onFocus = value
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },

        changeSelectedState(newState) {
            // Lógica portrait
            if (['portrait'].includes(this.$mq)) {
                this.bShowStates = !this.bShowStates
            }
            this.selectedState = newState

            for (let key in this.states) {
                let state = this.states[key]

                if (state.stateID == newState.stateID && state.collectionID == newState.collectionID && state.referenceID == newState.referenceID) {
                    this.iStateIndex = key
                }
            }

            this.generateProcess()
        },

        cancel(print) {
            // Popup if changes in step done
            var changes = false
            var self = this
            for (var step in this.data) {
                var index = this.data[step]
                if (index.result !== '' && index.keyname != 'state' && index.keyname != 'quantity') {
                    changes = true
                }
            }
            if (self.bUpdatedPrintDate) {
                changes = true
            }

            log('CANCEL', self.bUpdatedPrintDate, print, self.bUpdatedPrintDate, self.digitalState)
            if (changes && !print) {
                self.$popup.confirm({
                    message: self.$t('popup.label_print.message'),
                    textSave: self.$t('popup.label_print.continue'),
                    textCancel: self.$t('register.cancel_complete'),
                    callSave: function () {
                        self.$popup.close()
                    },
                    callCancel: function () {
                        self.$store.commit('label/referenceStateReset', {})
                        self.bShowStates = false
                        self.$emit('unselect')
                        self.$popup.close()
                    }
                })
            } else {
                self.$store.commit('label/referenceStateReset', {})
                self.bShowStates = false
                self.$emit('unselect')
            }
            // this.$store.commit('label/referenceStateReset', {})
            // this.bShowStates = false
            // this.$emit('unselect')
        },

        changePrintDate(dates) {
            const date = this.print_date.format('DD/MM/YYYY')
            const time = this.print_date.format('HH:mm')
            const self = this

            if (['portrait'].includes(this.$mq)) {
                const datetime = `${dates.date} ${dates.time}`
                self.print_date = moment(datetime, 'DD/MM/YYYY HH:mm')
                // if print date was modified, show alert, if is first time, validate if new date and time are different from old date and time
                self.bUpdatedPrintDate = self.bUpdatedPrintDate || (!self.bUpdatedPrintDate && (dates.date != dates.oldDate || dates.time != dates.oldTime))
                self.bUpdatedPrintDateVisible = self.bUpdatedPrintDate

                //UPDATE DATE CALENDAR RANGS
                for (var k in self.data) {
                    var step = self.data[k]
                    if (typeof step.minDate !== 'undefined' && typeof step.maxDate !== 'undefined') {
                        var minDate = undefined
                        var maxDate = self.print_date.format('DD/MM/YYYY')

                        if (step.keyname == 'dflevedacao') {
                            var auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                            minDate = auxDate.subtract(4, 'd').format('DD/MM/YYYY')
                            auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                            maxDate = auxDate.subtract(2, 'd').format('DD/MM/YYYY')
                        } else if (step.keyname == 'dftpambiente') {
                            var auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                            minDate = auxDate.subtract(6, 'd').format('DD/MM/YYYY')
                            auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                            maxDate = auxDate.subtract(2, 'd').format('DD/MM/YYYY')
                        }

                        step.minDate = minDate
                        step.maxDate = maxDate
                        self.data[k] = step
                    }
                }

                self.updatePreview()
            } else {
                // show popup
                this.$popup.dateTime({
                    date: date,
                    time: time,
                    only_past: this.bPastLabelling,
                    callSave: function (popupData, store) {
                        if (self.bPastLabelling) {
                            const now = moment()
                            const printDate = moment(`${popupData.date} ${popupData.time}`, 'DD/MM/YYYY HH:mm')
                            if (printDate.isAfter(now)) {
                                // self.$snackbar.error({
                                //     message: i18n.t('tooltip.print_date_out_range'),
                                //     closeable: true
                                // })
                                console.log(popupData)
                                popupData.showingError = true
                                popupData.messageError = i18n.t('tooltip.print_date_out_range')
                                return
                            }
                        }

                        const datetime = `${popupData.date} ${popupData.time}`
                        self.print_date = moment(datetime, 'DD/MM/YYYY HH:mm')
                        // if print date was modified, show alert, if is first time, validate if new date and time are different from old date and time
                        self.bUpdatedPrintDate = self.bUpdatedPrintDate || (!self.bUpdatedPrintDate && (popupData.date != popupData.oldDate || popupData.time != popupData.oldTime))
                        self.bUpdatedPrintDateVisible = self.bUpdatedPrintDate

                        //UPDATE DATE CALENDAR RANGS
                        for (var k in self.data) {
                            var step = self.data[k]
                            if (typeof step.minDate !== 'undefined' && typeof step.maxDate !== 'undefined') {
                                var minDate = undefined
                                var maxDate = self.print_date.format('DD/MM/YYYY')

                                if (step.keyname == 'dflevedacao') {
                                    var auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                                    minDate = auxDate.subtract(4, 'd').format('DD/MM/YYYY')
                                    auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                                    maxDate = auxDate.subtract(2, 'd').format('DD/MM/YYYY')
                                } else if (step.keyname == 'dftpambiente') {
                                    var auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                                    minDate = auxDate.subtract(6, 'd').format('DD/MM/YYYY')
                                    auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
                                    maxDate = auxDate.subtract(2, 'd').format('DD/MM/YYYY')
                                }

                                step.minDate = minDate
                                step.maxDate = maxDate
                                self.data[k] = step
                            }
                        }

                        self.changeDatePrintPortrait = false
                        self.updatePreview()
                        self.$popup.close()
                    },
                    callCancel: function () {
                        self.$popup.close()
                        self.changeDatePrintPortrait = false
                    }
                })
            }
        },

        goToConfig() {
            this.$router.push({
                name: 'ConfigPage',
                params: {
                    page: 'printer'
                }
            })
        },
        loadRelatedLabels(state_id) {
            const self = this

            let countSteps = 0
            for (let index in this.data) {
                let step = this.data[index]
                if (typeof step.type !== 'undefined' && ['string', 'date', 'datetime', 'datepast', 'datefree', 'datetimefree', 'datetimepast'].includes(step.type)) {
                    countSteps++
                }
            }

            if (typeof self.relatedLabels[state_id] == 'undefined' && countSteps > 0 && this.$store.getters['getRelatedLabels'] > 0 && typeof this.label.states[state_id].related_state_status !== 'undefined' && this.label.states[state_id].related_state_status) {
                this.$store
                    .dispatch('label/getRelatedLabels', {
                        state_id: state_id,
                        reference_id: self.processData.label.id,
                        format_id: self.label.states[state_id].format
                    })
                    .then((response) => {
                        self.relatedLabels[state_id] = response
                        self.relatedLabels = { ...self.relatedLabels }
                        this.isLoadedRelatedLabels = true

                        if (response.status === false) {
                            this.isErrorRelatedLabels = true
                        } else {
                            this.isErrorRelatedLabels = false
                        }
                    })
                    .catch((error) => {
                        this.isLoadedRelatedLabels = true
                        this.isErrorRelatedLabels = true
                    })
            }
        },
        selectRelatedValue(pType, i, value) {
            // declare variables to reuse in multiple cases
            let date
            switch (pType) {
                case 'string':
                    if (typeof this.$refs[`step-${i}`] !== 'undefined' && typeof this.$refs[`step-${i}`][0] !== 'undefined') {
                        // update selected related value in dom element
                        const stepRef = this.$refs[`step-${i}`][0]
                        stepRef.value = value
                    }
                    break
                case 'date':
                case 'datefree':
                case 'datepast':
                    date = moment(value, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
                    this.data[i].result = date
                    date = moment(date, 'DD/MM/YYYY').format('YYYY/MM/DD')
                    value = date
                    this.calendarKey++
                    break
                case 'datetime':
                case 'datetimefree':
                case 'datetimepast':
                    this.data[i].result = value
                    this.calendarKey++
                    break
            }
            this.setProcessResult(pType, i, value)
        },
        formatTimeToMax(timeToFormat) {
            // console.log(timeToFormat)
            if (typeof timeToFormat === 'undefined') return { result: null, format: 'minute' }
            var year = 365 * 24 * 60
            var month = 30 * 60 * 24
            var day = 60 * 24
            var hours = 60
            var result = false
            var format = false

            // if (timeToFormat % year == 0) {
            //     result = Math.floor(timeToFormat / year)
            //     format = 'year'
            // } else if (timeToFormat < year && timeToFormat % month == 0) {
            //     result = Math.floor(timeToFormat / month)
            //     format = 'month'
            // } else if (timeToFormat > year) {
            //     // Se calculan los años enteros y se obtienen los meses restantes, si los meses son enteros, los devuelve
            //     var years = Math.floor(timeToFormat / year)
            //     var months = timeToFormat - years * year
            //     if (months % month == 0) {
            //         result = Math.floor(months / month) + years * 12
            //         format = 'month'
            //     }
            // } else if (timeToFormat % day == 0) {
            //     result = Math.floor(timeToFormat / day)
            //     format = 'day'
            // } else if (timeToFormat % hours == 0) {
            //     result = Math.floor(timeToFormat / hours)
            //     format = 'hour'
            // } else {
            //     result = timeToFormat
            //     format = 'minute'
            // }
            result = timeToFormat
            format = 'minute'
            return { result: result, format: format }
        },
        reloadRelatedLabels() {
            const self = this
            this.$store
                .dispatch('label/getRelatedLabels', {
                    state_id: self.processData.selectedState.id,
                    reference_id: self.processData.label.id,
                    format_id: self.processData.label.states[self.processData.selectedState.id].format
                })
                .then((response) => {
                    self.relatedLabels[self.processData.selectedState.id] = response
                    self.relatedLabels = { ...self.relatedLabels }

                    this.isLoadedRelatedLabels = true

                    if (response.status === false) {
                        this.isErrorRelatedLabels = true
                    } else {
                        this.isErrorRelatedLabels = false
                    }
                })
                .catch((error) => {
                    this.isLoadedRelatedLabels = true
                    this.isErrorRelatedLabels = true
                })
        }
    },
    created() {
        this.productName = this.label.name
        this.processData = {
            label: this.label,
            selectedState: {}
        }

        this.timezone = this.$store.getters['getTimeZoneName']

        this.generateProcess()

        if (this.processData.selectedState) {
            this.loadRelatedLabels(this.processData.selectedState.id)
        }
    },
    mounted() {},
    watch: {
        $mq: function (newVal, oldVal) {
            if (newVal == 'portrait') {
                if (this.fullPreview) {
                    this.fullPreview = false
                    this.showPreviewPortrait = true
                }
            }
        },

        currentPrinter(newVal, oldVal) {
            this.validatePrinterConfiguration(true, true)
        }
    },
    updated() {}
}
</script>

<style lang="scss">
#content.label-print {
    > .content {
        padding: 6px !important;
        overflow: hidden !important;

        .full-preview {
            @include display-flex();
            @include justify-content();
            @include align-items(center);
            @include flex-direction(column);
            width: 100%;
            height: 100%;

            .btn-close {
                @include border-radius(4px);
                @include interaction;
                background-color: #fff;
                opacity: 0.7;
                margin-top: 25px;
                padding: 10px 20px;
                color: #444;
                font-family: $text-sbold;

                @include bgHover(darken(#fff, 5%));
            }

            .close-preview {
                position: absolute;
                @include background($image: img('close_preview_dark.svg'), $position: center center, $size: 20px, $color: #fff);
                @include border-radius(4px);
                @include interaction();
                width: 40px;
                height: 40px;
                top: 20px;
                right: 20px;
            }
        }

        #center-block {
            $pinfo-height: rem(35px);
            $wizard-height: rem(80px);

            @include border-radius($default-border-radius);
            width: 75%;
            // max-width: calc(100% - #{rem(300px)});
            height: 100%;
            background: #fff;
            display: inline-block;
            // overflow: auto;
            padding: 12px 0;

            .product-info {
                width: 100%;
                text-align: center;
                height: $pinfo-height;

                .product-title {
                    @include background($size: $pinfo-height, $position: left center);
                    @include font-size(l);
                    @include text-ellipsis();
                    width: fit-content;
                    font-family: $text-bold;
                    color: $main;
                    line-height: $pinfo-height;
                    margin: 0 auto;
                    max-width: 100%;

                    &.image {
                        padding-left: rem(60px);
                    }
                }
            }

            .wizard {
                width: 100%;
                height: $wizard-height;
                padding: 0 $alex-app-padding;
                text-align: center;

                .resume {
                    @include display-flex();
                    @include align-items();
                    @include justify-content();
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    width: calc(100% - #{rem(20px)});
                    height: rem(20px);
                    margin: 0 12px 12px 12px;

                    .resume-step {
                        border-top: 4px solid $neutro;
                        display: inline-block;
                        position: relative;

                        &:first-of-type {
                            border-color: transparent !important;
                            width: 0px !important;
                        }
                        &:last-of-type {
                        }

                        &:after {
                            @include border-radius(50%);
                            @include background($color: $neutro);
                            content: '';
                            width: rem(12px);
                            height: rem(12px);
                            position: absolute;
                            top: rem(-1 * 8px);
                            right: rem(-1 * 6px);
                            transition-duration: 0.1s;
                            z-index: $z-column;
                        }

                        &.done {
                            border-color: $main;

                            &:after {
                                background-color: $main;
                            }
                        }
                    }
                }

                .nav {
                    width: calc(100% - #{rem(20px)});
                    height: rem(35px);
                    margin: 0 10px;
                    line-height: rem(35px);

                    .action {
                        @include interaction();
                        width: 25%;
                        display: inline-block;
                        height: rem(35px);

                        .text {
                            @include text-ellipsis();
                            @include font-size(m);
                            display: inline-block;
                            width: calc(100% - #{rem(45px)});
                            color: $main;
                        }

                        &.right .text {
                            text-align: right;
                        }

                        button {
                            $margin: rem(10px);

                            display: inline-block;
                            width: rem(35px);
                            height: rem(35px);
                            border-radius: $default-border-radius;

                            &.back {
                                @include background($image: img('left_arrow_primary.svg'), $size: 25px, $position: center center, $color: $neutro);
                                margin-right: $margin;
                                @include bgHover($neutro);
                            }
                            &.next {
                                @include background($image: img('right_arrow_light.svg'), $size: 25px, $position: center center, $color: $main);
                                margin-left: $margin;
                                @include bgHover($main);
                            }
                        }

                        &.disabled {
                            .text {
                                color: $neutro;
                            }

                            button {
                                &.next {
                                    background-color: $inactive;
                                }
                            }
                        }
                    }

                    .current-action {
                        @include font-size(l);
                        line-height: 100%;
                        font-family: $text-bold;
                        text-align: center;
                        color: $main;
                        width: 50%;
                        min-height: rem(35px);
                        height: auto;
                        display: inline-block;
                        padding: 0 6px;
                    }
                }
            }

            .content {
                width: 100%;
                height: calc(100% - #{$pinfo-height + $wizard-height});
                min-height: 100px;
                padding: 24px 0;
                overflow-y: auto;

                .step {
                    @include height-fit-content();
                    width: 100%;
                    display: none;
                }
                .step.current {
                    display: block;
                }

                .step-message {
                    @include font-size(sm);
                    color: #666;
                    text-align: center;
                    margin-top: 0;
                }

                .input {
                    &.related {
                        flex-wrap: wrap;

                        .related-labels {
                            @include display-flex();
                            @include align-items(flex-start);
                            width: 100%;
                            padding: calc(0.375rem + 10px);
                        }
                    }
                }
            }

            .tags {
                height: rem(90px);
                width: 100%;
                padding-left: 24px;
                overflow-x: auto;
                margin-top: $alex-app-padding * 2;

                .carrousel {
                    @include display-inline-flex();
                    overflow-x: overlay;

                    .tag {
                        @include interaction();
                        @include border-radius($default-border-radius);
                        @include display-inline-flex();
                        @include align-items();
                        @include justify-content();
                        @include font-size(m);
                        color: $main;
                        font-family: $text-bold;
                        text-align: center;
                        text-transform: uppercase;
                        height: rem(90px);
                        width: auto;
                        max-width: 225px;
                        margin: 0 $alex-app-padding;
                        padding: 0 $alex-app-padding * 2;
                        background-color: $default-bg-color;

                        @include bgHover($default-bg-color);

                        &.selected {
                            background-color: $main;
                            color: #fff;
                            @include bgHover($main);
                        }
                    }
                }
            }
        }

        .column {
            $header: rem(70px);
            $footer: rem(185px);

            // min-width: rem(300px);
            width: calc(25% - #{$alex-app-padding});
            height: 100%;
            padding-left: $alex-app-padding;
            padding-right: 0;
            display: inline-block;
            position: relative;
            float: right;

            .collections_header {
                @include display-flex();
                @include align-items();
                height: $header;
                padding-bottom: 20px;

                .counter {
                    @include border-radius(4px);
                    @include display-inline-flex();
                    @include align-items();
                    flex-wrap: wrap;
                    height: rem(50px);
                    width: 80px;
                    background-color: #ffffff;

                    .value {
                        @include font-size(l);
                        font-family: $conden-bold;
                        text-align: center;
                        color: $main;
                        width: 100%;
                    }

                    .description {
                        @include font-size(sm);
                        font-family: $conden;
                        text-align: center;
                        color: $neutro-s50;
                        width: 100%;
                    }
                }

                .change_view {
                    @include font-size(sm);
                    width: 100%;
                    height: 100%;
                    padding-right: 6px;
                    text-align: right;

                    span {
                        @include background($image: img('right_arrow_dark.svg'), $size: 20px, $position: center right);
                        font-family: $text-medium;
                        color: $neutro-s70;
                        padding-right: 30px;
                        line-height: 50px;
                        text-align: right;
                        display: inline-block;
                        width: fit-content;
                        cursor: pointer;
                    }
                }
            }

            .titl {
                @include font-size(sm);
                font-family: $text-medium;
                color: $neutro-s90;
                text-align: left;
                width: 100%;
                margin-bottom: 6px;
            }

            select {
                @include border-radius(4px);
                @include font-size(sm);
                @include background($image: img('down_caret_neutro_s90.svg'), $position: center right 15px, $size: 10px);
                @include text-ellipsis();
                -webkit-appearance: none;
                -moz-appearance: none;
                font-family: $text-bold;
                color: $neutro-s90;
                background-color: #fff;
                border: none;
                padding-right: 30px;
                width: 100%;
                height: 40px;
            }

            .preview-info {
                @include display-flex();
                @include flex-direction(column);
                height: calc(100% - #{$footer});
                overflow: auto;
                padding: 0 15px;

                .preview-title {
                    @include display-flex();
                    @include align-items(center);
                    width: 100%;
                    height: 40px;
                    padding-bottom: 6px;
                    margin-bottom: 6px;
                    padding-top: 10px;
                    .titl {
                        @include font-size(sm);
                        font-family: $text-medium;
                        color: $neutro-s90;
                        text-align: left;
                        width: 100%;
                        margin-bottom: 6px;
                    }

                    .maximize-preview {
                        @include background($image: img('eye_preview_dark.svg'), $position: center center, $size: 20px, $color: #fff);
                        @include border-radius(4px);
                        @include interaction();
                        width: 40px;
                        height: 40px;
                    }
                }

                select {
                    @include border-radius(4px);
                    @include font-size(sm);
                    @include background($image: img('down_caret_neutro_s90.svg'), $position: center right 15px, $size: 10px);
                    @include text-ellipsis();
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    font-family: $text-bold;
                    color: $neutro-s90;
                    background-color: #fff;
                    border: none;
                    padding-right: 30px;
                    width: 100%;
                    height: 40px;
                }

                .preview {
                    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba(131, 131, 133, 0.25));
                    width: 100%;
                    min-height: 25%;
                    max-height: calc(100% - 24px - 23px - 10px);
                    height: auto;
                    overflow: auto;
                    background-color: #fff;
                    border: rem(2px) $check-answer !important;
                    border-radius: $default-border-radius;
                    margin-bottom: 6px;

                    span u {
                        text-decoration: underline;
                        font-family: $conden;
                    }

                    .row {
                        width: 100%;
                        min-height: rem(26px);
                        height: auto;
                        line-height: 100%;
                        padding: 0 3px;

                        &:nth-child(even) {
                            background: $neutro-t50;
                        }

                        .col {
                            display: inline-block;
                            margin: 0;
                            transition-duration: 0.1s;
                            line-height: rem(24px);

                            &.c1 {
                                width: 25%;
                            }
                            &.c2 {
                                width: 50%;
                            }
                            &.c3 {
                                width: 75%;
                            }
                            &.c4 {
                                width: 100%;
                            }

                            span {
                                @include font-size(sm);
                                @include text-ellipsis();
                                font-family: $conden;
                                display: block;

                                b,
                                em,
                                strong,
                                u,
                                i {
                                    font-size: inherit;
                                    font-family: inherit;
                                    white-space: inherit;
                                }
                            }

                            .vue-barcode-element {
                                height: 50px;
                            }

                            &.current {
                                @include border-radius(3px);
                                background-color: $progress;
                                padding: 2px 3px;
                                margin: 3px 0 0 0;

                                span {
                                    color: #fff;
                                    font-family: $conden-bold;
                                }
                            }
                        }
                    }
                }

                .print_date {
                    min-height: 40px;

                    .section {
                        @include display-flex();
                        @include align-items(flex-end);
                        @include justify-content(flex-end);
                        width: auto;

                        .label {
                            @include font-size(m);
                            font-family: $conden-light;
                            color: $neutro-s70;
                            margin-right: 3px;
                        }

                        .value {
                            @include font-size(m);
                            font-family: $conden;
                            color: $neutro-s70;
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        .modify_date {
                            @include background($image: img('alert_complem.svg'), $position: left center, $size: 15px);
                            padding-left: 20px;
                            @include font-size(m);
                            font-family: $conden;
                            color: $warning-s30;
                        }

                        &:nth-child(1) {
                            padding-top: 8px;
                        }
                    }
                }

                .quantity {
                    text-align: right;
                    width: 100%;
                    height: 32px;
                    padding-top: 8px;

                    span {
                        @include font-size(ml);
                        font-family: $text;
                        color: $neutro-s90;
                        .units {
                            font-family: $text-bold;
                        }
                    }
                }

                .states_labels {
                    width: 100%;
                    min-height: 25%;
                }
                &.collection {
                    height: calc(100% - 230px);
                }
                &.collection-desktop {
                    height: calc(100% - 317px);
                }
                &.desktop-labelling {
                    height: calc(100% - 245px);
                }
                &.desktop-labelling-collection {
                    height: calc(100% - 310px);
                }
                &.landscape-labelling {
                    height: calc(100% - 170px);
                }
                &.landscape-labelling-collection {
                    height: calc(100% - 225px);
                }
            }

            .preview-error {
                .error-message {
                    @include font-size(m);
                    font-family: $text-medium;
                }
                .btn {
                    @include default-box-shadow($o: 0.1);
                    margin-top: 12px;
                    background-color: #fff;
                    color: $neutro-s90;
                }
            }

            .footer {
                min-height: rem(40px);
                max-height: $footer;
                width: calc(100% - #{$alex-app-padding});
                position: absolute;
                bottom: 0;

                button {
                    @include interaction();
                    @include font-size(sm);
                    @include border-radius(6px);
                    border: 1px solid rgba(131, 131, 131, 0.03);
                    width: calc(100%);
                    padding: 10px 20px;
                    color: $main;
                    font-family: $text-light;
                    background-color: #fff;
                    float: right;

                    @include bgHover(#fff);

                    &.save {
                        @include font-size(xl);
                        color: #fff;
                        background-color: $main;
                        font-family: $text-bold;
                        border: none;
                        margin-top: 6px;
                        height: rem(100px);

                        @include bgHover($main);
                    }
                    &.print {
                        @include display-flex();
                        @include justify-content();
                        @include align-items(center);
                        flex-wrap: wrap;

                        &:before {
                            content: '';
                            @include background($image: img('printer_ffffff.svg'), $size: 35px);
                            margin-right: 15px;
                            width: 40px;
                            height: 40px;
                            display: inline-block;
                        }

                        &.digital {
                            &:before {
                                content: '';
                                @include background($image: img('save_ffffff.svg'), $size: 35px);
                                margin-right: 10px;
                                width: 40px;
                                height: 40px;
                                display: inline-block;
                            }
                        }
                    }
                    &.disabled {
                        opacity: 0.3;
                    }
                    &.cancel {
                        @include border-radius(6px);
                        @include font-size(l);
                        color: $neutro-s50;
                        float: left;
                        height: rem(60px);
                        margin-top: 6px;
                    }
                }
            }
        }
        &.landscape-tablet {
            $m-pinfo-height: rem(30px);
            $m-wizard-height: rem(75px);

            #center-block {
                width: 75%;
                max-width: calc(100% - #{rem(240px)});
                padding: 6px 0;

                .product-info {
                    height: $m-pinfo-height;

                    .product-title {
                        @include font-size(ml);
                        line-height: $m-pinfo-height;
                    }
                }

                .wizard {
                    height: $m-wizard-height;

                    .resume-step {
                        &:after {
                            width: rem(10px);
                            height: rem(10px);
                            top: rem(-1 * 7px);
                            right: rem(-1 * 5px);
                        }
                    }

                    .nav {
                        .action {
                            .text {
                                @include font-size(sm);
                                opacity: 0;
                            }
                        }
                        .current-action {
                            @include font-size(m);
                        }
                    }
                }

                .content {
                    height: calc(100% - #{$m-pinfo-height + $m-wizard-height});
                    padding-top: 6px;
                }

                .tags {
                    .carrousel {
                        .tag {
                            @include font-size(sm);
                        }
                    }
                }
            }

            .column {
                min-width: rem(240px);
                width: calc(25% - #{$alex-app-padding});

                .preview {
                    max-height: 45%;

                    .row {
                        .col {
                            @include font-size(s);
                        }
                    }
                }

                .change_view {
                    span {
                        line-height: 25px !important;
                    }
                }

                .quantity {
                    @include font-size(m);
                }

                .footer {
                    button {
                        &.save {
                            @include font-size(l);
                            height: rem(60px);
                        }
                        &.print {
                            @include display-flex();
                            @include justify-content();
                            @include align-items(center);
                            flex-wrap: wrap;
                            &:before {
                                background-size: 25px;
                                margin-right: 8px;
                                width: 30px;
                                height: 30px;
                            }
                        }
                        &.cancel {
                            @include font-size(m);
                            height: rem(40px);
                        }
                    }
                }
            }
            &.condensed {
                #center-block {
                    .product-info {
                        display: none;
                    }
                }

                .column {
                    .titl,
                    .preview {
                        display: none;
                    }
                    .preview-title {
                        display: none;
                    }
                    // #preview-container {
                    //     display: none;
                    // }
                    .footer {
                        button.cancel {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#content.label-print {
    .content {
        &.portrait {
            #center-block {
                @include display-flex();
                @include flex-direction(column);

                padding: 5px;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                background-color: transparent;

                .product-info {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items(center);
                    width: 100%;
                    height: auto;

                    .product-title {
                        @include font-size(20px);
                        @include text-ellipsis();
                        background-size: contain;
                        width: fit-content;
                        font-family: $text-medium;
                        color: $neutro-s80;
                        margin: 0px;
                    }
                    .collection-btn {
                        @include border-radius(4px);
                        @include background($image: img('collection_list_portrait.svg'), $size: 28px);
                        background-color: #fff;
                        width: 45px;
                        height: 45px;
                        cursor: pointer;
                        margin-left: auto;
                        min-width: 45px;
                        margin-right: 7px;

                        &.selected {
                            @include background($image: img('collection_list_portrait_active.svg'), $size: 28px);
                            background-color: $main-dark;
                        }
                    }
                    .preview-btn {
                        @include border-radius(4px);
                        @include background($image: img('eye_preview_dark.svg'), $size: 28px);
                        background-color: #fff;
                        width: 45px;
                        height: 45px;
                        min-width: 45px;
                        cursor: pointer;

                        &.selected {
                            @include background($image: img('eye_preview_light.svg'), $size: 28px);
                            background-color: $main-dark;
                        }
                    }
                    .counter {
                        @include display-flex();
                        @include border-radius(4px);
                        background-color: #fff;
                        padding: 10px 20px 10px 10px;
                        .value {
                            @include font-size(xl);
                            color: $main-dark;
                            font-family: $conden-bold;
                            padding-right: 6px;
                        }
                        .description {
                            @include font-size(18px);
                            font-family: $conden;
                            color: $neutro-s50;
                            text-transform: uppercase;
                        }
                    }

                    &.collection {
                        padding-bottom: 25px;
                    }
                }
                .preview-info {
                    height: 100%;
                    max-height: calc(100% - 80px);
                    padding: 0px 15px;

                    .states_labels {
                        height: inherit;
                        overflow-y: scroll;
                    }
                }
                .wizard {
                    width: 100%;
                    height: 35px;
                    padding: 0px;
                    margin-top: 20px;

                    .nav {
                        @include display-flex();
                        @include justify-content(space-between);
                        @include align-items(center);
                        height: 100%;
                        width: 100%;
                        margin: 0px;

                        .current-action {
                            @include display-flex();
                            @include justify-content();
                            @include align-items();
                            @include font-size(17px);
                            // @include text-ellipsis();
                            width: fit-content;
                            padding: 0px;
                            margin: 0px;
                            font-family: $text-sbold;
                            height: fit-content;
                            line-height: 17px;
                        }

                        .action {
                            height: 35px;
                            width: 35px;

                            .next,
                            .back {
                                width: 35px;
                                height: 35px;
                                margin-left: 0px;
                                margin-right: 0px;
                            }
                            &.none {
                                opacity: 0;
                            }
                        }

                        .empty {
                            display: none;
                        }
                    }
                }
                // PASOS DEL WIZARD
                .content {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto !important;

                    .step {
                        width: 100%;
                        height: 100%;

                        .tags {
                            padding: 0px;
                            margin: 0px;
                            width: 100%;
                            height: 100%;

                            .carrousel {
                                @include display-flex();
                                @include flex-direction(column);
                                @include align-items(center);
                                gap: 6px;
                                height: auto;
                                width: 100%;
                                margin: 0 auto;
                                max-width: 300px;

                                .tag {
                                    @include border-radius(3px);
                                    background-color: #fff;
                                    width: 100%;
                                    margin: 0px;
                                    height: 6rem;
                                    padding: 5px;

                                    &.selected {
                                        background-color: $main-dark;
                                    }
                                }
                            }
                        }

                        .input-text {
                            @include display-flex();
                            height: 100%;
                            width: 100%;

                            .input-ticketing {
                                @include background($image: img('input_text_portrait.svg'), $position: 15px center, $size: 30px);
                                align-self: center;
                                padding-right: 10px;
                                background-color: #fff;
                            }
                        }

                        .input {
                            &.related {
                                .input-container,
                                .calendar-container {
                                    height: unset;
                                }

                                .calendar-container {
                                    margin-bottom: 30px;
                                }

                                .related-labels {
                                    padding: 0;

                                    .related-values {
                                        .container {
                                            .item {
                                                @include font-size(s);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .input-number {
                            height: 100%;

                            .pin {
                                background-color: transparent;
                                width: 5rem !important;
                            }
                        }
                        // Calendario adaptado a portrait
                        .p-date {
                            width: 100%;
                            height: 100%;

                            .calendar-container {
                                width: 100%;
                                height: 100%;
                                margin-top: 0px;

                                .vfc-styles-conditional-class {
                                    @include display-flex();
                                    @include align-items(center);
                                    width: 100%;
                                    height: 100%;
                                    flex-direction: column;

                                    .vfc-main-container {
                                        @include border-radius(8px);
                                        width: 100%;
                                        height: auto !important;
                                        padding: 18px 10px;

                                        .vfc-calendars {
                                            width: 100%;
                                            height: 100%;
                                            background-color: #fff;

                                            .vfc-content {
                                                background-color: #fff;
                                                width: 100%;
                                                height: auto;
                                                margin: 0 !important;

                                                .vfc-marked {
                                                    background-color: $main-dark !important;
                                                }

                                                .vfc-top-date {
                                                    position: relative;
                                                    top: -8px;
                                                }
                                            }
                                        }

                                        .vfc-navigation-buttons {
                                            height: 40px;
                                            .vfc-cursor-pointer {
                                                width: 40px;
                                                height: 40px;
                                                background-color: #eeeeee;

                                                .vfc-arrow-right {
                                                    top: -5px;
                                                    right: 0px;
                                                }
                                                .vfc-arrow-left {
                                                    top: -5px;
                                                    left: 5px;
                                                }
                                                .vfc-disabled {
                                                    top: 0px !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // Fin estilos calendario
                    }
                }
                .footer {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items(center);
                    width: 100%;
                    height: 50px;
                    z-index: 10;

                    .act {
                        @include border-radius(4px);
                        padding: 12.5px 35px;
                        font-family: $text-bold;
                        width: 49%;

                        &.cancel {
                            background-color: #e3e7ed;
                            color: $main;
                        }
                        &.save {
                            background-color: $main-dark;
                            color: #fff;

                            &.disabled {
                                background-color: #bfbfcc;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }

            .column.right {
                position: absolute;
                top: 75px;
                left: 0;
                width: 100%;
                background-color: $default-bg-color;
                height: calc(100% - 120px);
                overflow: hidden;
                z-index: 4;
                padding: 0;

                .preview-error {
                    padding: 0 15px !important;
                }

                .titl {
                    padding: 0 15px !important;

                    select {
                        margin-top: 10px;
                    }
                }

                .preview-info {
                    height: calc(100% - 65px);
                    overflow: hidden;

                    &.portrit-selector {
                        height: calc(100% - 10px - 80px);
                        padding-top: 10px;
                    }

                    .preview-scrollbar {
                        max-height: unset;
                    }
                }

                .titl {
                    text-align: center;
                    color: $main-dark;
                    min-height: rem(35px);
                    display: inline-block;
                    @include font-size(24px);
                    width: 100%;
                    padding: 0px;
                    margin: 0px;
                    font-family: $text-sbold;
                    height: fit-content;
                    line-height: inherit;
                }

                .print_date {
                    min-height: unset;

                    .section {
                        @include border-radius(4px);
                        @include display-flex();
                        @include justify-content(space-between);
                        @include align-items(center);
                        width: 100%;
                        background-color: #fff;
                        padding: 10px 15px;
                        margin: 10px 0;
                        .meta {
                            width: 60%;
                            .label {
                                @include font-size(s);
                                font-family: $text-light;
                                color: $neutro-s70;
                            }

                            .value {
                                @include font-size(s);
                                font-family: $text-bold;
                                text-decoration: none;
                                cursor: default;
                            }
                        }

                        .action {
                            @include text-ellipsis();
                            @include display-flex();
                            @include justify-content(center);
                            @include align-items(center);
                            @include border-radius(4px);
                            @include font-size(sm);
                            font-family: $text-bold;
                            background-color: $neutro;
                            color: $main;
                            width: 40%;
                            text-align: center;
                            height: 50px;
                            cursor: pointer;
                        }
                    }

                    .section.date {
                        padding: 0;
                        background-color: transparent;

                        .modify_date {
                            @include font-size(s);
                            font-family: $text-bold;
                            background-size: 16px;
                            padding-left: 22px;
                            color: $warning-s20;
                        }
                    }
                }

                .portrait-footer {
                    @include display-flex();
                    @include justify-content(flex-end);
                    @include align-items(center);
                    width: calc(100% - 26px);
                    margin: 0 auto;
                    height: 50px;
                    position: absolute;
                    bottom: 19px;

                    .act {
                        @include border-radius(4px);
                        padding: 12.5px 35px;
                        font-family: $text-bold;
                        height: 50px;

                        &.cancel {
                            background-color: #e3e7ed;
                            color: $main;
                        }
                        &.save {
                            background-color: $main-dark;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
</style>
